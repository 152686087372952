import { useLocalization } from '@fluent/react';
import { Box, Button, CardContent, CardHeader, IconButton } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import denmFormReducer from '_store/forms/manage-denm';
import { DenmType, DenmTypeEnum } from '../types';
import DenmCreate from './DenmCreate';
import styles from './styles';
import { selectedRoadSegmentIdsSelector } from '_store/roads/selectors';
import denmsReducer from '_store/denm/denmsReducers';
import DenmManage from './DenmManage';
import { getFromLocalStorage } from '../../../../../utils';

const DenmLayout: FC<DenmType> = ({ id, type, onGoingBack, onClose, closeDialog }) => {
  const { l10n } = useLocalization();
  const classes = styles();
  const selectedRoadSegmentIds: string[] = useSelector(selectedRoadSegmentIdsSelector);

  let LayoutType = null;
  let CardHeaderTitle = null;

  const dispatch = useDispatch();
  useEffect(() => {
    if (type === DenmTypeEnum.NEW_DENM) {
      const savedFormState = getFromLocalStorage('tempDenmFormState');
      const savedMainTrace = getFromLocalStorage('tempDenmMainTrace');

      if (!savedFormState && !savedMainTrace) {
        dispatch(denmFormReducer.actions.resetForm());
      }

      dispatch(denmsReducer.actions.denmsRequest({ roadSegmentIds: selectedRoadSegmentIds }));
    }
  }, [type]);

  switch (type) {
    case DenmTypeEnum.NEW_DENM:
      CardHeaderTitle = l10n.getString('forms-denm-add-new');
      LayoutType = <DenmCreate closeDialog={closeDialog} />;
      break;
    case DenmTypeEnum.UPDATE_DENM: {
      CardHeaderTitle = `${l10n.getString('forms-denm-update-denm')}`;
      LayoutType = <DenmManage id={id} type={type} closeDialog={closeDialog} />;
      break;
    }
    case DenmTypeEnum.CLONE_DENM: {
      CardHeaderTitle = `${l10n.getString('forms-denm-clone-denm')}`;
      LayoutType = <DenmManage id={id} type={type} closeDialog={closeDialog} />;
      break;
    }
    case DenmTypeEnum.DELETE_DENM:
      LayoutType = null;
      break;

    case DenmTypeEnum.DISABLE_DENM:
      LayoutType = null;
      break;

    default:
      CardHeaderTitle = `Add a new DENM`;
      LayoutType = null;
  }

  return (
    <CardContent className={classes.cardContent}>
      <Box className={classes.box}>
        <Button
          variant="text"
          onClick={() => {
            onGoingBack();
            dispatch(denmFormReducer.actions.resetGeoNetworking());
          }}
          size="small"
          className={classes.backBnt}
          startIcon={<ArrowBackIosIcon />}
        >
          {l10n.getString('back')}
        </Button>

        <IconButton
          className={classes.closeBnt}
          onClick={() => {
            onClose();
            dispatch(denmFormReducer.actions.resetGeoNetworking());
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <CardHeader
        title={CardHeaderTitle}
        className={classes.header}
        titleTypographyProps={{ variant: 'h6' }}
      />

      {LayoutType}
    </CardContent>
  );
};

export default React.memo(DenmLayout);
