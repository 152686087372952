import { RootState } from '_store';
import applicationReducer from '_store/application/reducer';
import { selectionSelector } from '_store/application/selectors';
import { PointSelectionType } from '_store/application/types';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useMapEvents } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import ContextMenu from './ContextMenu';
import { measureDistance } from '../utils';
import { Coordinate } from '_lib/api';
import { useLocalization } from '@fluent/react';
import { TraceType } from '../../../scenes/Denm/CreateDenm/components/MainTrace';

const MAX_DISTANCE = 0.75;

const MapEvents: FC = () => {
  const [contextMenuPosition, setContextMenuPosition] = useState(null);
  const mapRef = useRef(null);
  const contextMenuRef = useRef(null);
  const { l10n } = useLocalization();

  const dispatch = useDispatch();
  const selection = useSelector(selectionSelector);
  const pointSelection = useSelector((state: RootState) => state.application.pointSelections);
  const { active: psActive, points } = pointSelection;
  const currentSelection = useSelector((state: RootState) => state.application.currentSelection);
  const formState = useSelector((state: RootState) => state.forms['manage-denm']);
  const traceType = useSelector((state: RootState) => state.application.traceType);

  const showToast = useCallback(() => {
    dispatch(
      applicationReducer.actions.toastAdded({
        id: l10n.getString('toast-error-distance-too-long'),
      }),
    );
  }, [dispatch, l10n]);

  const handleClick = useCallback(
    (clickEvent) => {
      if (
        contextMenuRef.current &&
        contextMenuRef.current.contains(clickEvent.originalEvent.target)
      ) {
        return;
      }

      if (selection.active) {
        const newPoint: Coordinate = [clickEvent.latlng.lng, clickEvent.latlng.lat];

        // Verifica la distanza solo per DENM_HISTORY e DENM_TRACES, e solo se non è una traccia automatica
        if (
          (currentSelection === PointSelectionType.DENM_HISTORY ||
            (currentSelection === PointSelectionType.DENM_TRACES &&
              traceType !== TraceType.AUTOMATIC)) &&
          points.length > 0
        ) {
          // Verifica la distanza dal punto precedente
          const lastPoint = points[points.length - 1] as Coordinate;
          const distance = measureDistance(lastPoint, newPoint);

          if (distance > MAX_DISTANCE) {
            showToast();
            return;
          }

          // Se è il primo punto, verifica anche la distanza dal punto di riferimento
          if (points.length === 1 && formState.referencePoint) {
            const distanceFromRef = measureDistance(
              formState.referencePoint,
              points[0] as Coordinate,
            );
            if (distanceFromRef > MAX_DISTANCE) {
              showToast();
              return;
            }
          }

          // Se è un nuovo trace, verifica la distanza dall'ultimo punto dell'ultimo trace
          if (
            currentSelection === PointSelectionType.DENM_TRACES &&
            points.length === 1 &&
            formState.tracezoneCoordinates?.length
          ) {
            const lastTrace =
              formState.tracezoneCoordinates[formState.tracezoneCoordinates.length - 1];
            const lastTracePoint = lastTrace[lastTrace.length - 1];
            const distanceFromLastTrace = measureDistance(lastTracePoint, points[0] as Coordinate);
            if (distanceFromLastTrace > MAX_DISTANCE) {
              showToast();
              return;
            }
          }
        }

        // Se tutte le verifiche sono passate, aggiungo il punto
        dispatch(applicationReducer.actions.pointSelected(newPoint));
      }

      if (!psActive && currentSelection !== PointSelectionType.MEASUREMENT) {
        setContextMenuPosition(null);
      }
    },
    [dispatch, selection, psActive, currentSelection, points, formState, traceType],
  );

  const handleContextMenu = useCallback((e) => {
    setContextMenuPosition(e.latlng);
  }, []);

  const handleClickOutside = useCallback(
    (event) => {
      if (
        contextMenuRef.current &&
        !contextMenuRef.current.contains(event.target) &&
        psActive &&
        currentSelection !== PointSelectionType.MEASUREMENT
      ) {
        setContextMenuPosition(null);
      }
    },
    [psActive, currentSelection],
  );

  const map = useMapEvents({
    click: handleClick,
    contextmenu: handleContextMenu,
  });

  mapRef.current = map;

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  if (!contextMenuPosition) return null;

  const point = mapRef.current.latLngToContainerPoint(contextMenuPosition);

  return (
    <div ref={contextMenuRef}>
      <ContextMenu
        point={point}
        position={contextMenuPosition}
        onClose={() => setContextMenuPosition(null)}
      />
    </div>
  );
};

export default MapEvents;
