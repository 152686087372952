import { makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

const styles = makeStyles((theme) => ({
  dialog: {
    margin: '0 auto',
    width: 800,
  },
  dialogContent: {
    //width: 800,
    padding: theme.spacing(3),
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  filterRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    gap: 100,
  },
  filterSecondRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  table: {
    minWidth: 650,
  },
  tablePagination: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  tableCellRight: {
    textAlign: 'right',
  },
  chip: {
    padding: 5,
    paddingLeft: 10,
    borderColor: green[500],
    color: green[500],
    textTransform: 'uppercase',
  },
}));

export default styles;
