import { useLocalization } from '@fluent/react';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  Tooltip,
} from '@material-ui/core';
import RotateLeftRoundedIcon from '@material-ui/icons/RotateLeftRounded';
import { RootState, useAppDispatch } from '_store';
import denmsReducer from '_store/denm/denmsReducers';
import { DenmDateFilterType, DenmSourceFilter, DenmStatusFilter } from '_store/denm/denmsTypes';
import {
  causesSelector,
  denmFilterCauseSelector,
  denmFilterDateSelector,
  denmFilterExcludeCauseAndSubcauseSelector,
  denmFilterSincroDisaptchableSelector,
  denmFilterSourceSelector,
  denmFilterStatusSelector,
  denmFilterSubCauseSelector,
  denmFilterUpdatedFromSelector,
  denmFilterUpdatedToSelector,
  subCausesByCauseSelector,
} from '_store/denm/selectors';
import { selectedRoadSegmentIdsSelector } from '_store/roads/selectors';
import { DatePeriodEnum } from '_store/utils';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import DateItem from '../../../../../components/items/DateItem';
import MultiSelectItem from '../../../../../components/items/MultiSelectItem';
import SelectItem from '../../../../../components/items/SelectItem';

interface PopoverFiltersProps {
  setChecked: (checked: string[]) => void;
  setFilteredStatus: (status: DenmStatusFilter | '') => void;
  itemsPerPage: number;
}

export const PopoverFilters = ({
  setChecked,
  setFilteredStatus,
  itemsPerPage,
}: PopoverFiltersProps) => {
  const { l10n } = useLocalization();
  const dispatch = useAppDispatch();

  const denmsStatus = useSelector(denmFilterStatusSelector);
  const denmsSources = useSelector(denmFilterSourceSelector);
  const denmsDate = useSelector(denmFilterDateSelector);
  const denmsSubCause = useSelector(denmFilterSubCauseSelector);
  const denmsCause = useSelector(denmFilterCauseSelector);
  const denmsUpdatedFrom = useSelector(denmFilterUpdatedFromSelector);
  const denmsUpdatedTo = useSelector(denmFilterUpdatedToSelector);
  const denmDateFilterType = useSelector((state: RootState) => state.denms.dateFilterType);
  const denmsSincroDispatchable = useSelector(denmFilterSincroDisaptchableSelector);
  const denmsExcludeCauseAndSubcause = useSelector(denmFilterExcludeCauseAndSubcauseSelector);

  const selectedRoadSegmentIds: string[] = useSelector(selectedRoadSegmentIdsSelector);

  const denmCauses = useSelector(causesSelector);
  const denmEvents = useSelector(subCausesByCauseSelector);
  const { busy } = useSelector((state: RootState) => state.application.activeDialog);
  const causes = denmCauses.map(({ id, description }) => ({
    value: id,
    label: description,
  }));

  const [dateRangeType, setDateRangeType] = useState(denmDateFilterType);
  const [filters, setFilters] = useState({
    status: denmsStatus,
    date: denmsDate,
    sources: denmsSources,
    cause: denmsCause,
    subCause: denmsSubCause,
    updatedFrom: denmsUpdatedFrom,
    updatedTo: denmsUpdatedTo,
    sincroDispatchable: denmsSincroDispatchable,
    excludeCauseAndSubcause: denmsExcludeCauseAndSubcause,
  });

  const subcauses = useMemo(
    () =>
      filters.cause === null
        ? []
        : denmEvents.map(({ id, description }) => ({
            value: id,
            label: description,
          })),
    [filters, denmEvents],
  );

  const statusOptions = useMemo(
    () => [
      { value: DenmStatusFilter.IN_PROGRESS, labelKey: 'forms-event-status-events-prog' },
      { value: DenmStatusFilter.CLOSED, labelKey: 'forms-event-status-events-clos' },
      { value: DenmStatusFilter.DISABLED, labelKey: 'forms-event-status-events-disabled' },
    ],
    [],
  );

  const datePeriodOptions = useMemo(
    () => [
      { value: DatePeriodEnum.lastHour, labelKey: 'forms-event-date-last-hour' },
      { value: DatePeriodEnum.lastDay, labelKey: 'forms-event-date-last-day' },
      { value: DatePeriodEnum.lastWeek, labelKey: 'forms-event-date-last-week' },
      { value: DatePeriodEnum.lastMonth, labelKey: 'forms-event-date-last-month' },
      { value: DatePeriodEnum.last3Months, labelKey: 'forms-event-date-last-3-months' },
      { value: DatePeriodEnum.last6Months, labelKey: 'forms-event-date-last-6-months' },
      { value: DatePeriodEnum.lastYear, labelKey: 'forms-event-date-last-year' },
    ],
    [],
  );

  const sourceOptions = useMemo(
    () => [
      { value: DenmSourceFilter.EMERAS, labelKey: 'forms-event-source-emeras' },
      { value: DenmSourceFilter.CONNEX, labelKey: 'forms-event-source-vehicles' },
      { value: DenmSourceFilter.DATEXII, labelKey: 'forms-event-source-datexii' },
    ],
    [],
  );

  const updateFiltersAndDispatch = useCallback(
    (newFilters) => {
      setChecked([]);
      setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
      dispatch(
        denmsReducer.actions.denmsRequest({
          status: newFilters.status !== undefined ? newFilters.status : filters.status,
          roadSegmentIds: selectedRoadSegmentIds,
          date: newFilters.date || filters.date,
          sources: newFilters.sources || filters.sources,
          causeId: newFilters.cause !== undefined ? newFilters.cause : filters.cause,
          subCauseId: newFilters.subCause !== undefined ? newFilters.subCause : filters.subCause,
          updatedFrom:
            newFilters.updatedFrom !== undefined ? newFilters.updatedFrom : filters.updatedFrom,
          updatedTo: newFilters.updatedTo !== undefined ? newFilters.updatedTo : filters.updatedTo,
          sincroDispatchable:
            'sincroDispatchable' in newFilters
              ? newFilters.sincroDispatchable
              : filters.sincroDispatchable,
          excludeCauseAndSubcause:
            'excludeCauseAndSubcause' in newFilters
              ? newFilters.excludeCauseAndSubcause
              : filters.excludeCauseAndSubcause,
          currentPage: 1,
          itemsPerPage,
        }),
      );
    },
    [dispatch, filters, selectedRoadSegmentIds, setChecked],
  );

  const filterByStatus = (ev: any) => {
    const status = ev.target.value as DenmStatusFilter;
    updateFiltersAndDispatch({ status });
    setFilteredStatus(status);
  };

  const filterByDatePeriod = (ev: any) => {
    const date = ev.target.value as DatePeriodEnum;
    updateFiltersAndDispatch({ date, updatedFrom: null, updatedTo: null });
  };

  const filterBySource = (ev: any) => {
    const sources = ev.target.value as DenmSourceFilter[];
    updateFiltersAndDispatch({ sources });
  };

  const filterUpdatedTo = (date: Date) => {
    updateFiltersAndDispatch({ updatedTo: date });
  };

  const filterUpdatedFrom = (date: Date) => {
    updateFiltersAndDispatch({ updatedFrom: date });
  };

  const updateCause = (e) => {
    const id = e.target.value;
    dispatch(denmsReducer.actions.subCausesByCauseRequest(id));
    updateFiltersAndDispatch({ cause: id, subCause: '' });
  };

  const updateSubCause = (e) => {
    const id = e.target.value;
    setChecked([]);
    updateFiltersAndDispatch({ subCause: id });
  };

  const resetCauseAndSubcause = () => {
    setChecked([]);
    updateFiltersAndDispatch({ cause: '', subCause: '' });
  };

  const handleDateFilterRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as DenmDateFilterType;
    setDateRangeType(value);
    dispatch(denmsReducer.actions.changeDateFilterType(value));

    if (value === DenmDateFilterType.RELATIVE) {
      filterByDatePeriod({ target: { value: DatePeriodEnum.lastDay } });
    }
    if (value === DenmDateFilterType.ABSOLUTE) {
      updateFiltersAndDispatch({ updatedFrom: null, updatedTo: null });
    }
  };

  const handleSincroDispatchableChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    updateFiltersAndDispatch({ sincroDispatchable: value });
  };

  const handleExcludeCauseAndSubcause = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    updateFiltersAndDispatch({ excludeCauseAndSubcause: value });
  };

  const resetStatusFilter = () => {
    updateFiltersAndDispatch({ status: '' });
    setFilteredStatus('');
  };

  return (
    <>
      <Box style={{ display: 'grid', gridTemplateColumns: '4fr 4fr 1fr', gap: 8 }} marginBottom={2}>
        <MultiSelectItem
          text={l10n.getString('forms-event-filter-source')}
          value={filters.sources ?? []}
          options={sourceOptions.map(({ value, labelKey }) => ({
            value,
            label: l10n.getString(labelKey),
          }))}
          onChange={filterBySource}
          tooltip={l10n.getString('forms-event-filter-source')}
          style={{ gridColumn: 1 / 2 }}
        />
        <SelectItem
          text={l10n.getString('forms-event-filter-status')}
          value={filters.status ?? ''}
          options={statusOptions.map(({ value, labelKey }) => ({
            value,
            label: l10n.getString(labelKey),
          }))}
          onChange={filterByStatus}
          tooltip={l10n.getString('forms-event-filter-status')}
          style={{ gridColumn: 1 / 2 }}
        />
        <Tooltip title={l10n.getString('forms-denm-filters-reset-status')} placement="top">
          <RotateLeftRoundedIcon
            onClick={resetStatusFilter}
            style={{ cursor: 'pointer', marginTop: 40, marginLeft: 10 }}
          />
        </Tooltip>
      </Box>
      <Box style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 8 }} marginBottom={2}>
        <FormControlLabel
          control={
            <Switch
              checked={filters.sincroDispatchable}
              onChange={handleSincroDispatchableChange}
              name="sincroDispatchable"
              color="primary"
            />
          }
          label={l10n.getString('forms-denm-filters-sincro-dispatchable')}
        />
      </Box>
      <Divider style={{ margin: '30px 0' }} />
      <Box>
        <FormControl component="fieldset">
          <FormLabel component="legend">{l10n.getString('forms-event-filter-by-date')}</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={dateRangeType}
            onChange={handleDateFilterRadio}
            row
          >
            <FormControlLabel
              value={DenmDateFilterType.RELATIVE}
              control={<Radio color="primary" />}
              label={l10n.getString('forms-event-filter-by-date-relative-range')}
            />
            <FormControlLabel
              value={DenmDateFilterType.ABSOLUTE}
              control={<Radio color="primary" />}
              label={l10n.getString('forms-event-filter-by-date-absolute-range')}
              color="primary"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      {dateRangeType === 'relative' && (
        <Box style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 8, marginBottom: 16 }}>
          <SelectItem
            text={l10n.getString('forms-event-filter-date')}
            value={filters.date ?? ''}
            options={datePeriodOptions.map(({ value, labelKey }) => ({
              value,
              label: l10n.getString(labelKey),
            }))}
            onChange={filterByDatePeriod}
            tooltip={l10n.getString('forms-event-filter-date')}
            style={{ gridColumn: 1 / 2 }}
          />
        </Box>
      )}
      {dateRangeType === 'absolute' && (
        <Box
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 8,
            marginBottom: 16,
            marginTop: 16,
          }}
        >
          <DateItem
            label={l10n.getString('forms-event-created-from')}
            value={filters.updatedFrom}
            disabled={busy}
            onChange={filterUpdatedFrom}
            tooltip={l10n.getString('forms-event-updated-from-tooltip')}
            disablePast={false}
            maxDate={filters.updatedTo ?? new Date()}
          />
          <DateItem
            label={l10n.getString('forms-event-created-to')}
            value={filters.updatedTo}
            disabled={busy}
            onChange={filterUpdatedTo}
            tooltip={l10n.getString('forms-event-updated-to-tooltip')}
            disablePast={false}
            minDate={filters.updatedFrom ?? new Date()}
          />
        </Box>
      )}
      <Divider style={{ margin: '30px 0' }} />
      <Box>
        <FormControlLabel
          control={
            <Switch
              checked={filters.excludeCauseAndSubcause}
              onChange={handleExcludeCauseAndSubcause}
              name="excludeCauseAndSubcause"
              color="primary"
            />
          }
          label={l10n.getString('forms-denm-filters-cause-exclude')}
        />
      </Box>
      <Box style={{ display: 'grid', gridTemplateColumns: '7fr 7fr 1fr', gap: 8 }} marginBottom={2}>
        <SelectItem
          text={l10n.getString('forms-denm-cause')}
          value={filters.cause ?? ''}
          options={causes}
          disabled={busy}
          onChange={updateCause}
          tooltip={l10n.getString('forms-denm-cs-tp')}
        />
        <SelectItem
          text={l10n.getString('forms-denm-subcause')}
          value={filters.subCause ?? ''}
          options={subcauses}
          disabled={filters.cause === null || busy}
          onChange={updateSubCause}
          tooltip={l10n.getString('forms-denm-sb-cs-tp')}
        />
        <Tooltip title={l10n.getString('forms-denm-filters-reset-causes')} placement="top">
          <RotateLeftRoundedIcon
            onClick={resetCauseAndSubcause}
            style={{ cursor: 'pointer', marginTop: 40, marginLeft: 10 }}
          />
        </Tooltip>
      </Box>
    </>
  );
};
