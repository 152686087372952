import { useLocalization } from '@fluent/react';
import { Button, CardContent, Grid, TextField, Tooltip, Typography } from '@material-ui/core';
import { Coordinate, CreateIVIMPayload } from '_lib/api';
import { RootState, useAppDispatch } from '_store';
import applicationReducer from '_store/application/reducer';
import {
  MapElementType,
  PointCount,
  PointSelectionParams,
  PointSelectionType,
  DialogType,
} from '_store/application/types';
import ivimFormReducer, { CreateIVIMForm } from '_store/forms/manage-ivim';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ivimsReducer from '_store/ivim/ivimsReducers';
import { ivimType } from '_store/ivim/ivimsTypes';
import { serviceProviderOption, textLanguageISO639 } from '_store/ivim/utils';
import { roadsSelector, selectedRoadSegmentIdsSelector } from '_store/roads/selectors';
import { PictogramsModal } from '../../../../components/items/PictogramsModal';
import ButtonGroup from '../../../items/ButtonGroup';
import DateItem from '../../../items/DateItem';
import FreeformSelect from '../../../items/FreeformSelect';
import SelectItem from '../../../items/SelectItem';
import { pointStyles } from '../../../routes/MapView/utils';
import styles from './styles';
import { settingsSelector } from '_store/settings/selectors';
import { updateLocalStorage, getFromLocalStorage } from '../../../../../utils';
import SubmitModal from './components/SubmitModal';

// TODO Replace this with a proper validation library (when we have more time)
export function isFormValid(form: CreateIVIMForm): boolean {
  return (
    form.countryCode !== null &&
    form.providerIdentifier !== null &&
    form.language !== null &&
    form.pictogramNature !== null &&
    form.pictogramSerialNumber !== null &&
    form.iviType !== null &&
    form.validfrom !== null &&
    form.validto !== null &&
    form.referencePoint !== null &&
    form.detZoneCoordinates !== null &&
    form.detZoneCoordinates.length > 0 &&
    form.relZoneCoordinates !== null &&
    form.relZoneCoordinates.length > 0 &&
    form.broadcastingFrequency !== null &&
    !Number.isNaN(form.broadcastingFrequency) &&
    form.serviceCategoryCode !== null
  );
}

interface CreateIvimProps {
  closeDialog: () => void;
}

const CreateIvim = ({ closeDialog }: CreateIvimProps) => {
  const { l10n } = useLocalization();
  const classes = styles();
  const dispatch = useAppDispatch();

  const [showPictogramsModal, setShowPictogramsModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [newFormState, setNewFormState] = useState<CreateIVIMPayload>(null);

  const formState = useSelector((state: RootState) => state.forms['create-ivim']);
  const pointSelection = useSelector((state: RootState) => state.application.pointSelections);
  const currentSelection = useSelector((state: RootState) => state.application.currentSelection);
  const roads = useSelector(roadsSelector);
  const selectedRoadSegmentIds: string[] = useSelector(selectedRoadSegmentIdsSelector);
  const settings = useSelector(settingsSelector);

  const { busy } = useSelector((state: RootState) => state.application.activeDialog);

  // Cleanup custom elements
  useEffect(
    () => () => {
      dispatch(
        applicationReducer.actions.removeMapElements(PointSelectionType.IVIM_REFERENCE_POINT),
      );
      dispatch(applicationReducer.actions.removeMapElements(PointSelectionType.IVIM_REL_ZONE));
      dispatch(applicationReducer.actions.removeMapElements(PointSelectionType.IVIM_DET_ZONE));
    },
    [],
  );

  useEffect(() => {
    const savedFormState = getFromLocalStorage('tempIvimFormState');
    if (savedFormState) {
      if (savedFormState.countryCode) {
        dispatch(ivimFormReducer.actions.serviceCountryCodeChanged(savedFormState.countryCode));
      }
      if (savedFormState.providerIdentifier) {
        dispatch(
          ivimFormReducer.actions.serviceCountryIDChanged(savedFormState.providerIdentifier),
        );
      }
      if (savedFormState.iviType) {
        dispatch(ivimFormReducer.actions.typeChanged(savedFormState.iviType));
      }
      if (savedFormState.validfrom) {
        dispatch(ivimFormReducer.actions.validFromChanged(new Date(savedFormState.validfrom)));
      }
      if (savedFormState.validto) {
        dispatch(ivimFormReducer.actions.validToChanged(new Date(savedFormState.validto)));
      }
      if (savedFormState.roadSegmentId) {
        dispatch(ivimFormReducer.actions.roadSegmentIdChanged(savedFormState.roadSegmentId));
      }
      if (savedFormState.referencePoint) {
        dispatch(ivimFormReducer.actions.referencePointChanged(savedFormState.referencePoint));

        const referencePointElements = [
          {
            ...pointStyles[PointSelectionType.IVIM_REFERENCE_POINT],
            loc: savedFormState.referencePoint,
          },
        ];

        dispatch(
          applicationReducer.actions.setMapElement({
            id: PointSelectionType.IVIM_REFERENCE_POINT,
            elements: referencePointElements,
          }),
        );
      }
      if (savedFormState.detZoneCoordinates) {
        dispatch(ivimFormReducer.actions.detzoneChanged(savedFormState.detZoneCoordinates));

        const detZoneElements = savedFormState.detZoneCoordinates.map((loc) => ({
          ...pointStyles[PointSelectionType.IVIM_DET_ZONE],
          loc,
        }));

        if (savedFormState.detZoneCoordinates.length > 1) {
          detZoneElements.push({
            type: MapElementType.POLYLINE,
            loc: savedFormState.detZoneCoordinates[0],
            points: savedFormState.detZoneCoordinates.map((p, _i, arr) => [
              p[0] - arr[0][0],
              p[1] - arr[0][1],
            ]),
            options: {
              color: pointStyles[PointSelectionType.IVIM_DET_ZONE].options.color,
            },
          });
        }

        dispatch(
          applicationReducer.actions.setMapElement({
            id: PointSelectionType.IVIM_DET_ZONE,
            elements: detZoneElements,
          }),
        );
      }
      if (savedFormState.relZoneCoordinates) {
        dispatch(ivimFormReducer.actions.relzoneChanged(savedFormState.relZoneCoordinates));

        const relZoneElements = savedFormState.relZoneCoordinates.map((loc) => ({
          ...pointStyles[PointSelectionType.IVIM_REL_ZONE],
          loc,
        }));

        if (savedFormState.relZoneCoordinates.length > 1) {
          relZoneElements.push({
            type: MapElementType.POLYLINE,
            loc: savedFormState.relZoneCoordinates[0],
            points: savedFormState.relZoneCoordinates.map((p, _i, arr) => [
              p[0] - arr[0][0],
              p[1] - arr[0][1],
            ]),
            options: {
              color: pointStyles[PointSelectionType.IVIM_REL_ZONE].options.color,
            },
          });
        }

        dispatch(
          applicationReducer.actions.setMapElement({
            id: PointSelectionType.IVIM_REL_ZONE,
            elements: relZoneElements,
          }),
        );
      }
      if (savedFormState.text) {
        dispatch(ivimFormReducer.actions.textChanged(savedFormState.text));
      }
      if (savedFormState.extratext) {
        dispatch(ivimFormReducer.actions.extraTextChanged(savedFormState.extratext));
      }
      if (savedFormState.language) {
        dispatch(ivimFormReducer.actions.languageTextChanged(savedFormState.language));
      }
      if (savedFormState.serviceCategoryCode) {
        dispatch(
          ivimFormReducer.actions.serviceCategoryCodeChanged(savedFormState.serviceCategoryCode),
        );
      }
      if (savedFormState.serviceSubCategoryCode) {
        dispatch(
          ivimFormReducer.actions.serviceSubCategoryCodeChanged(
            savedFormState.serviceSubCategoryCode,
          ),
        );
      }
      if (savedFormState.broadcastingFrequency) {
        dispatch(
          ivimFormReducer.actions.broadcastingFrequencyChanged(
            savedFormState.broadcastingFrequency,
          ),
        );
      }
    }
  }, []);

  /*
    Set next action as primary (WRT point selection)
    There's probably a better way to do this but I couldn't come up with one
  */
  const pointFlow = () => {
    if (formState.referencePoint === null) {
      return pointSelection.active ? 1 : 0;
    }
    if (formState.detZoneCoordinates === null) {
      return pointSelection.active ? 3 : 2;
    }
    if (formState.relZoneCoordinates === null) {
      return pointSelection.active ? 5 : 4;
    }
    return 6;
  };
  const currentStep = pointFlow();

  const isActiveSelection = (selection: string) =>
    pointSelection.active && currentSelection === selection;

  const pointSelectionClick = (
    params: PointSelectionParams,
    setter: (coord: Coordinate[]) => void,
    index: number,
  ) => {
    switch (index) {
      case 0:
        dispatch(applicationReducer.actions.pointSelectionStarted(params));
        dispatch(applicationReducer.actions.removeMapElements(params.type));
        if (params.type === PointSelectionType.IVIM_REFERENCE_POINT) {
          dispatch(ivimFormReducer.actions.detzoneChanged([]));
          dispatch(ivimFormReducer.actions.relzoneChanged([]));
          dispatch(
            applicationReducer.actions.removeMultiMapElements([
              PointSelectionType.IVIM_REL_ZONE,
              PointSelectionType.IVIM_DET_ZONE,
            ]),
          );
        }
        break;
      case 1: {
        // Take existing points
        setter(pointSelection.points);

        // Show them on map
        const elements = pointSelection.points.map((loc) => ({
          ...pointStyles[params.type],
          loc,
        }));

        // If multiple points have been selected, draw a line between them
        if (pointSelection.points.length > 1) {
          elements.push({
            type: MapElementType.POLYLINE,
            loc: pointSelection.points[0],
            points: pointSelection.points.map((p, i, arr) => [p[0] - arr[0][0], p[1] - arr[0][1]]),
            options: {
              color: pointStyles[params.type].options.color,
            },
          });
        }

        dispatch(
          applicationReducer.actions.setMapElement({
            id: params.type,
            elements,
          }),
        );

        // Stop recording coordinates
        dispatch(applicationReducer.actions.pointSelectionAborted());
        break;
      }
      default:
        break;
    }
  };

  const submitForm = () => {
    const newFormState: CreateIVIMPayload = {
      iviType: formState.iviType,
      sequenceNumber: 0,
      serviceCategoryCode: formState.serviceCategoryCode,
      serviceSubCategoryCode: formState.serviceSubCategoryCode,
      pictogramNature: formState.pictogramNature,
      pictogramSerialNumber: formState.pictogramSerialNumber,
      language: formState.language,
      countryCode: formState.countryCode,
      providerIdentifier: formState.providerIdentifier,
      direction: 0,
      starts: String(formState.validfrom),
      ends: String(formState.validto),
      referencePoint: {
        type: 'Point',
        coordinates: formState.referencePoint,
      },
      relZoneCoordinates: {
        type: 'LineString',
        coordinates: formState.relZoneCoordinates,
      },
      detZoneCoordinates: {
        type: 'LineString',
        coordinates: formState.detZoneCoordinates,
      },
      roadSegmentId:
        formState.roadSegmentId ??
        (selectedRoadSegmentIds && selectedRoadSegmentIds.length === 1
          ? selectedRoadSegmentIds[0]
          : null),
      broadcastingFrequency: formState.broadcastingFrequency,
      text: [formState.text, formState.extratext],
    };

    setNewFormState(newFormState);

    if (!settings.distribution.denm.emeras) {
      const completeFormState = {
        ...formState,
        validfrom: formState.validfrom,
        validto: formState.validto,
        referencePoint: formState.referencePoint,
        detZoneCoordinates: formState.detZoneCoordinates,
        relZoneCoordinates: formState.relZoneCoordinates,
        text: formState.text,
        extratext: formState.extratext,
        language: formState.language,
        serviceCategoryCode: formState.serviceCategoryCode,
        serviceSubCategoryCode: formState.serviceSubCategoryCode,
        broadcastingFrequency: formState.broadcastingFrequency,
      };

      updateLocalStorage('tempIvimFormState', completeFormState);
      setOpenModal(true);
    } else {
      createIvim({ formState: newFormState });
    }
  };

  const confirmSubmitForm = () => {
    setOpenModal(false);
    createIvim({ formState: newFormState });
  };

  const createIvim = ({ formState }: { formState: CreateIVIMPayload }) => {
    updateLocalStorage('tempIvimFormState', null);
    dispatch(ivimsReducer.actions.createIVIM(formState));
    closeDialog();
    dispatch(ivimFormReducer.actions.resetForm());
  };

  const navigateToSettings = () => {
    updateLocalStorage('tempIvimFormState', {
      ...formState,
      validfrom: formState.validfrom,
      validto: formState.validto,
      referencePoint: formState.referencePoint,
      detZoneCoordinates: formState.detZoneCoordinates,
      relZoneCoordinates: formState.relZoneCoordinates,
      text: formState.text,
      extratext: formState.extratext,
      language: formState.language,
      serviceCategoryCode: formState.serviceCategoryCode,
      serviceSubCategoryCode: formState.serviceSubCategoryCode,
      broadcastingFrequency: formState.broadcastingFrequency,
    });

    setOpenModal(false);

    setTimeout(() => {
      dispatch(
        applicationReducer.actions.infoSelected({
          type: DialogType.SETTINGS,
          id: null,
        }),
      );
    }, 100);

    closeDialog();
  };

  // Set defaults
  useEffect(() => {
    if (formState.validfrom === null) {
      dispatch(ivimFormReducer.actions.validFromChanged(new Date()));
    }
    if (formState.validto === null) {
      dispatch(ivimFormReducer.actions.validToChanged(new Date(Date.now() + 10 * 60 * 1000)));
    }
  });

  return (
    <CardContent className={classes.cardContent}>
      <SelectItem
        text={l10n.getString('forms-ivim-sp-country-code')}
        options={textLanguageISO639.map((n) => ({
          value: n.value,
          label: n.label,
        }))}
        disabled={busy}
        value={formState.countryCode ?? ''}
        onChange={(ev) =>
          dispatch(ivimFormReducer.actions.serviceCountryCodeChanged(ev.target.value))
        }
        tooltip={l10n.getString('forms-ivim-sp-country-code-tt')}
      />
      <SelectItem
        text={l10n.getString('forms-ivim-sp-country-id')}
        options={[serviceProviderOption]}
        disabled={busy}
        value={formState.providerIdentifier ?? ''}
        onChange={(ev) =>
          dispatch(ivimFormReducer.actions.serviceCountryIDChanged(ev.target.value))
        }
        tooltip={l10n.getString('forms-ivim-sp-country-id-tt')}
        capitalizeLabels
      />
      <SelectItem
        text={l10n.getString('forms-ivim-type')}
        options={ivimType()}
        value={formState.iviType ?? ''}
        disabled={busy}
        onChange={(ev) => dispatch(ivimFormReducer.actions.typeChanged(ev.target.value))}
        tooltip={l10n.getString('forms-ivim-type-tp')}
      />

      <Grid container spacing={2} className={classes.cardContent} style={{ flexDirection: 'row' }}>
        <Grid item xs={true} sm={true}>
          <DateItem
            label={l10n.getString('forms-ivim-valid-from')}
            value={formState.validfrom ?? new Date()}
            disabled={busy}
            onChange={(date) => dispatch(ivimFormReducer.actions.validFromChanged(date))}
            tooltip={l10n.getString('forms-ivim-vl-from-tp')}
          />
        </Grid>

        <Grid item xs={true} sm={true}>
          <DateItem
            label={l10n.getString('forms-ivim-valid-to')}
            value={formState.validto ?? new Date()}
            disabled={busy}
            onChange={(date) => dispatch(ivimFormReducer.actions.validToChanged(date))}
            tooltip={l10n.getString('forms-ivim-vl-to-tp')}
          />
        </Grid>
      </Grid>

      <SelectItem
        text={l10n.getString('forms-rsu-road')}
        options={roads.map((road) => ({ label: road.name, value: road.id }))}
        value={
          formState.roadSegmentId ??
          (selectedRoadSegmentIds && selectedRoadSegmentIds.length == 1
            ? selectedRoadSegmentIds[0]
            : '')
        }
        onChange={(ev) => dispatch(ivimFormReducer.actions.roadSegmentIdChanged(ev.target.value))}
        tooltip={l10n.getString('forms-rsu-road')}
      />

      <ButtonGroup
        text={l10n.getString('forms-ivim-reference-point')}
        buttons={[
          {
            label: isActiveSelection(PointSelectionType.IVIM_REFERENCE_POINT)
              ? l10n.getString('forms-points-end-selection')
              : l10n.getString('forms-points-start-selection'),
            disabled:
              (pointSelection.active &&
                !isActiveSelection(PointSelectionType.IVIM_REFERENCE_POINT)) ||
              busy ||
              currentStep < 0,
            color: currentStep >= 0 ? 'primary' : 'default',
          },
        ]}
        onClick={() => {
          const index = isActiveSelection(PointSelectionType.IVIM_REFERENCE_POINT) ? 1 : 0;
          pointSelectionClick(
            {
              type: PointSelectionType.IVIM_REFERENCE_POINT,
              pointCount: PointCount.ONE,
              style: pointStyles[PointSelectionType.IVIM_REFERENCE_POINT],
            },
            (points) => dispatch(ivimFormReducer.actions.referencePointChanged(points[0])),
            index,
          );
        }}
      />
      <ButtonGroup
        text={l10n.getString('forms-ivim-det-zone')}
        buttons={[
          {
            label: isActiveSelection(PointSelectionType.IVIM_DET_ZONE)
              ? l10n.getString('forms-points-end-selection')
              : l10n.getString('forms-points-start-selection'),
            disabled:
              (pointSelection.active && !isActiveSelection(PointSelectionType.IVIM_DET_ZONE)) ||
              busy ||
              currentStep < 2,
            color: currentStep >= 2 ? 'primary' : 'default',
          },
        ]}
        onClick={() => {
          const index = isActiveSelection(PointSelectionType.IVIM_DET_ZONE) ? 1 : 0;
          pointSelectionClick(
            {
              type: PointSelectionType.IVIM_DET_ZONE,
              pointCount: PointCount.MANY,
              style: pointStyles[PointSelectionType.IVIM_DET_ZONE],
            },
            (points) => dispatch(ivimFormReducer.actions.detzoneChanged(points)),
            index,
          );
        }}
      />
      <ButtonGroup
        text={l10n.getString('forms-ivim-rel-zone')}
        buttons={[
          {
            label: isActiveSelection(PointSelectionType.IVIM_REL_ZONE)
              ? l10n.getString('forms-points-end-selection')
              : l10n.getString('forms-points-start-selection'),
            disabled:
              (pointSelection.active && !isActiveSelection(PointSelectionType.IVIM_REL_ZONE)) ||
              busy ||
              currentStep < 4,
            color: currentStep >= 4 ? 'primary' : 'default',
          },
        ]}
        onClick={() => {
          const index = isActiveSelection(PointSelectionType.IVIM_REL_ZONE) ? 1 : 0;
          pointSelectionClick(
            {
              type: PointSelectionType.IVIM_REL_ZONE,
              pointCount: PointCount.MANY,
              style: pointStyles[PointSelectionType.IVIM_REL_ZONE],
            },
            (points) => dispatch(ivimFormReducer.actions.relzoneChanged(points)),
            index,
          );
        }}
      />
      <Tooltip title={l10n.getString('forms-ivim-txt-tp')} placement="right">
        <TextField
          id="text"
          label={l10n.getString('forms-ivim-text')}
          InputLabelProps={{ style: { fontSize: 13 } }}
          value={formState.text ?? ''}
          disabled={busy}
          onChange={(ev) => dispatch(ivimFormReducer.actions.textChanged(ev.target.value))}
          inputProps={{ maxLength: 8 }}
          style={{ marginTop: 10 }}
        />
      </Tooltip>
      <Tooltip title={l10n.getString('forms-ivim-ex-txt-tp')} placement="right">
        <TextField
          id="extra-text"
          label={l10n.getString('forms-ivim-extra-text')}
          InputLabelProps={{ style: { fontSize: 13 } }}
          value={formState.extratext ?? ''}
          disabled={busy}
          onChange={(ev) => dispatch(ivimFormReducer.actions.extraTextChanged(ev.target.value))}
          inputProps={{ maxLength: 18 }}
          style={{ marginTop: 10 }}
        />
      </Tooltip>
      <SelectItem
        text={l10n.getString('forms-ivim-language-text')}
        options={textLanguageISO639.map((n) => ({
          value: n.value,
          label: l10n.getString(`language-${n.label}`, { defaultValue: n.label }),
        }))}
        disabled={busy}
        value={formState.language ?? ''}
        onChange={(ev) => dispatch(ivimFormReducer.actions.languageTextChanged(ev.target.value))}
        tooltip={l10n.getString('forms-ivim-lg-txt-tp')}
      />

      <Button
        disabled={busy}
        variant="outlined"
        className={classes.pictogramSelectButton}
        onClick={() => setShowPictogramsModal(true)}
      >
        {l10n.getString('forms-ivim-pictogram-select')}
      </Button>

      <PictogramsModal
        isOpen={showPictogramsModal}
        onClose={() => {
          setShowPictogramsModal(false);
        }}
        busy={busy}
        formStateServiceCategoryCode={formState.serviceCategoryCode}
        formStateServiceSubCategoryCode={formState.serviceSubCategoryCode}
        onChangeServiceCategoryCode={(ev) =>
          dispatch(ivimFormReducer.actions.serviceCategoryCodeChanged(ev.target.value))
        }
        onChangeServiceSubCategoryCode={(ev) =>
          dispatch(ivimFormReducer.actions.serviceSubCategoryCodeChanged(ev.target.value))
        }
        pictogramSelected={formState.pictogramText}
        onChangePictogram={(pictogram) =>
          dispatch(ivimFormReducer.actions.pictogramChanged(pictogram))
        }
      />

      {formState.serviceCategoryCode && (
        <Typography component={'span'} variant={'body2'} paragraph className={classes.textStyle}>
          {l10n.getString('forms-ivim-ser-cat-cod')} {`\n`}{' '}
          <strong>
            {l10n.getString(`forms-ivim-ser-cat-cod-enum-${formState.serviceCategoryCode}`)}
          </strong>
        </Typography>
      )}

      {formState.serviceSubCategoryCode && (
        <Typography component={'span'} variant={'body2'} paragraph className={classes.textStyle}>
          {l10n.getString('forms-ivim-ser-sub-cat-cod')} {`\n`}{' '}
          <strong>
            {l10n.getString(`forms-ivim-ser-cat-sub-cod-enum-${formState.serviceSubCategoryCode}`)}
          </strong>
        </Typography>
      )}

      {formState.pictogramText && (
        <Typography component={'span'} variant={'body2'} paragraph className={classes.textStyle}>
          {l10n.getString('forms-ivim-pictogram-name')} {`\n`}{' '}
          <strong>{formState.pictogramText}</strong>
        </Typography>
      )}

      <FreeformSelect
        label={l10n.getString('forms-ivim-broadcasting-frequency')}
        presets={['1000', '5000', '10000']}
        value={
          isNaN(formState.broadcastingFrequency)
            ? ''
            : formState.broadcastingFrequency?.toString() ?? ''
        }
        disabled={busy}
        onChange={(ev, value) =>
          dispatch(ivimFormReducer.actions.broadcastingFrequencyChanged(parseFloat(value)))
        }
        tooltip={l10n.getString('forms-ivim-br-fr-tp')}
      />

      <Button
        variant="outlined"
        disabled={!isFormValid(formState) || busy}
        onClick={submitForm}
        style={{ textTransform: 'none', marginTop: 10 }}
      >
        {l10n.getString('forms-actions-submit')}
      </Button>

      <SubmitModal
        open={openModal}
        onConfirm={confirmSubmitForm}
        onNavigateToSettings={navigateToSettings}
      />
    </CardContent>
  );
};

export default React.memo(CreateIvim);
