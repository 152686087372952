import { makeStyles } from '@material-ui/core';

const styles = makeStyles((_theme) => ({
  cardContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginTop: 0,
    paddingBottom: 0,
    flexGrow: 0,
  },
  textStyle: {
    whiteSpace: 'pre-line',
  },
  pictogramSelectButton: {
    textTransform: 'none',
    marginTop: 20,
    marginBottom: 20,
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
}));

export default styles;
