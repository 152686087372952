import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styles from './styles';
import { DialogTitle } from './components/DialogTitle';
import { useLocalization } from '@fluent/react';
import SelectItem from '../SelectItem';
import * as ivimEvents from '../../../assets/ivimEvents.json';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { PictogramsTable } from './components/Table';
import { IVIMPictrogram } from '_store/api/types';

interface PictogramsModalInterface {
  isOpen: boolean;
  onClose: () => void;
  busy: boolean;
  formStateServiceCategoryCode: string;
  formStateServiceSubCategoryCode: string;
  onChangeServiceCategoryCode: (event: React.ChangeEvent<{ name?: string; value: any }>) => void;
  onChangeServiceSubCategoryCode: (event: React.ChangeEvent<{ name?: string; value: any }>) => void;
  pictogramSelected?: string;
  onChangePictogram: (pictogram: IVIMPictrogram) => void;
}

export const PictogramsModal = ({
  isOpen,
  onClose,
  busy,
  formStateServiceCategoryCode,
  formStateServiceSubCategoryCode,
  onChangeServiceCategoryCode,
  onChangeServiceSubCategoryCode,
  pictogramSelected,
  onChangePictogram,
}: PictogramsModalInterface) => {
  const classes = styles();
  const { l10n } = useLocalization();

  const [filter, setFilter] = useState('');
  const [showOnlyGraphicPictograms, setShowOnlyGraphicPictograms] = useState(false);
  const [roadType, setRoadType] = useState('');
  const [pictogramNames, setPictogramNames] = useState<{ value: IVIMPictrogram; label: string }[]>(
    [],
  );

  const serviceCategoryCode = React.useMemo(
    () => [...new Set(ivimEvents.map((i) => i.serviceCategoryCode))],
    [],
  );

  const serviceSubCategoryCode = React.useMemo(
    () =>
      formStateServiceCategoryCode === null
        ? []
        : [
            ...new Set(
              ivimEvents
                .filter((ev) => ev.serviceCategoryCode === formStateServiceCategoryCode)
                .map((ev) => ev.serviceSubCategoryCode),
            ),
          ],
    [formStateServiceCategoryCode],
  );

  useEffect(() => {
    const filtered = ivimEvents
      .filter(
        (ev) =>
          ev.serviceCategoryCode === formStateServiceCategoryCode &&
          ev.serviceSubCategoryCode === formStateServiceSubCategoryCode,
      )
      .filter((ev) => {
        if (showOnlyGraphicPictograms) {
          if (ev.roadType === '') return false;
          if (roadType === '') return true;
          if (ev.roadType.toLowerCase() === 'all') return true;
          return ev.roadType.toLowerCase() === roadType.toLowerCase();
        }
        return true;
      })
      .map((ev) => ({
        value: ev,
        label: ev.description,
      }));

    setPictogramNames(filtered);
  }, [
    formStateServiceCategoryCode,
    formStateServiceSubCategoryCode,
    showOnlyGraphicPictograms,
    roadType,
  ]);

  const handleShowOnlyGraphicPictogramsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowOnlyGraphicPictograms(event.target.checked);
    setRoadType('');
  };

  const handleRoadTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRoadType(event.target.value.toLowerCase());
  };

  const handleClose = () => {
    onClose();
    setFilter('');
    setRoadType('');
  };

  const filteredPictogramsBySearch = React.useMemo(
    () =>
      pictogramNames
        .filter((p) => p.label.toLowerCase().includes(filter.toLowerCase()))
        .map((p) => p.value),
    [pictogramNames, filter],
  );

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        className={classes.dialog}
        maxWidth="lg"
      >
        <DialogTitle onClose={handleClose}>
          {l10n.getString('forms-ivim-pictogram-modal-title')}
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContent}>
          <div className={classes.filterRow}>
            <SelectItem
              text={l10n.getString('forms-ivim-ser-cat-cod')}
              options={serviceCategoryCode.map((n) => ({
                value: n,
                label: l10n.getString(`forms-ivim-ser-cat-cod-enum-${n}`, {
                  defaultValue: n,
                }),
              }))}
              disabled={busy}
              value={formStateServiceCategoryCode ?? ''}
              onChange={(ev) => onChangeServiceCategoryCode(ev)}
              tooltip={l10n.getString('forms-ivim-sv-cat-code-tp')}
              style={{ width: '150px' }}
              labelStyle={{ width: '150px' }}
            />

            <SelectItem
              text={l10n.getString('forms-ivim-ser-sub-cat-cod')}
              options={serviceSubCategoryCode.map((n) => ({
                value: n,
                label: l10n.getString(`forms-ivim-ser-cat-sub-cod-enum-${n}`, {
                  defaultValue: n,
                }),
              }))}
              disabled={formStateServiceCategoryCode === null || busy}
              value={formStateServiceSubCategoryCode ?? ''}
              onChange={(ev) => onChangeServiceSubCategoryCode(ev)}
              tooltip={l10n.getString('forms-ivim-sv-sub-cat-code-tp')}
              style={{ width: '150px' }}
              labelStyle={{ width: '150px' }}
            />
            <TextField
              style={{ marginTop: 16 }}
              key={'search'}
              label={l10n.getString('forms-ivim-pictogram-search')}
              InputLabelProps={{ variant: 'standard' }}
              onChange={(ev) => setFilter(ev.target.value)}
              fullWidth
              type="text"
              value={filter}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton edge="start" size="small">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),

                endAdornment: filter && (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setFilter('')}
                    size="small"
                  >
                    <CloseIcon />
                  </IconButton>
                ),
              }}
            />
          </div>
          <div className={classes.filterSecondRow}>
            <FormControlLabel
              control={
                <Switch
                  checked={showOnlyGraphicPictograms}
                  onChange={handleShowOnlyGraphicPictogramsChange}
                  name="showOnlyGraphicPictograms"
                  color="primary"
                />
              }
              label={l10n.getString('forms-ivim-filters-show-only-graphic-pictograms')}
            />
            {showOnlyGraphicPictograms && (
              <SelectItem
                text={l10n.getString('forms-ivim-road-type')}
                options={['Urban', 'Motorway'].map((n) => ({
                  value: n.toLowerCase(),
                  label: l10n.getString(`forms-ivim-road-type-enum-${n}`, {
                    defaultValue: n,
                  }),
                }))}
                disabled={busy}
                value={roadType}
                onChange={handleRoadTypeChange}
                tooltip={l10n.getString('forms-ivim-road-type')}
                style={{ width: '250px' }}
                formControlStyle={{ marginTop: -16 }}
              />
            )}
          </div>
          <div>
            <PictogramsTable
              rows={filteredPictogramsBySearch}
              rowSelected={pictogramSelected}
              selectPictogram={(row) => onChangePictogram(row)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            {l10n.getString('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
