import { Coordinate, DENMOptional } from '_lib/api';

export interface TraceZoneCoordinates {
  type: string;
  bbox: number[];
  coordinates: Coordinate[][];
}

export interface HistoryZoneCoordinates {
  type: string;
  bbox: number[];
  coordinates: Coordinate[];
}

export interface ReferencePoint {
  type: string;
  bbox: number[];
  coordinates: Coordinate;
}

interface NearestRoadMetadata {
  roadTag: string;
  km: number;
  distance: number;
}

export interface DenmSincroMetaData {
  startPoint: NearestRoadMetadata | null;
  endPoint: NearestRoadMetadata | null;
  dispatchable: boolean;
  nonDispatchableReason: string;
}

export interface DenmEtsiMetaData {
  actionID: {
    sequenceNumber: number;
    originatingStationID: number;
  };
  speedValue: number;
  stationType: number;
  relevanceDistance: string;
  informationQuality: number;
  relevanceTrafficDirection: string;
}
interface DenmMetaData {
  etsi?: DenmEtsiMetaData;
  sincro?: DenmSincroMetaData;
  rsus?: string[];
}

export interface Denm {
  traceZoneCoordinates: TraceZoneCoordinates;
  historyZoneCoordinates: HistoryZoneCoordinates;
  referencePoint: ReferencePoint;
  causeId: string;
  subCauseId: string;
  validityPeriod: number;
  broadcastingFrequency: number;
  id: string;
  roadSegmentId: string;
  expiresAt: string;
  createdAt: string;
  updatedAt: string;
  disabled: boolean;
  source: DenmSourceFilter;
  metadata: DenmMetaData;
  datexiiId?: string;
  optional?: DENMOptional;
  validityDuration: number;
  validityDurationPercentage: number;
}

export interface DenmPayload {
  results: Denm[];
}

export interface Causes {
  code: number;
  description: string;
  iconUploadKey: string;
  id: string;
}

export interface SubCauses {
  code: number;
  description: string;
  causeId: string;
  id: string;
}

export interface DenmEntities {
  [id: string]: Denm;
}

export enum DenmStatusFilter {
  IN_PROGRESS = 'in_progress',
  CLOSED = 'closed',
  DISABLED = 'disabled',
}

export enum DenmSourceFilter {
  EMERAS = 'emeras',
  CONNEX = 'connex',
  DATEXII = 'datexii',
}

export enum DenmDateFilterType {
  RELATIVE = 'relative',
  ABSOLUTE = 'absolute',
}

export interface DenmReducerState {
  subscriptionId?: string;
  loading: boolean;
  // ids: string[];
  // entities: DenmEntities;
  loadingCauses: boolean;
  causes: Causes[];
  loadingSubCauses: boolean;
  subCauses: SubCauses[];
  loadingSubCausesByCause: boolean;
  subCausesByCause: SubCauses[];
  statusFilter: DenmStatusFilter | '';
  sourceFilter: DenmSourceFilter[];
  dateFilter: string;
  subCauseFilter: string;
  causeFilter: string;
  createdFromFilter: Date;
  createdToFilter: Date;
  updatedFromFilter: Date;
  updatedToFilter: Date;
  dateFilterType: DenmDateFilterType;
  metadata: DenmMetaData;
  roadSegmentId: string;
  sincroDispatchableFilter: boolean;
  allDenmsData?: Denm[];
  excludeCauseAndSubcauseFilter: boolean;
  loadingCSV: boolean;
  optionalSubCausesByCause?: SubCauses[];
  total: number;
  showDenmFilteredById?: boolean;
  paginationCurrentPage?: number;
  paginationItemsPerPage?: number;
}
