[
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 11,
    "description": "Warning of the existence of a cross-shaped intersection ahead",
    "roadType": "Urban"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 12,
    "description": "Warning of the existence of a side road ahead",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 13,
    "description": "Warning of a side road ahead",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 14,
    "description": "Warning of the existence if a side road ahead. Fork type of code no.112",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 15,
    "description": "Warning of the existence if a side road ahead. Fork type of code no.113",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 16,
    "description": "Warning of a T-Shaped level junction at the end of the road",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 17,
    "description": "Warning of a Y-Shaped level junction at the end of the road",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 18,
    "description": "Warning of a staggered level intersection ahead",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 32,
    "description": "Warning of a priority road at intersection",
    "roadType": "Urban"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 33,
    "description": "Warning of a priority road at T-shaped crossing",
    "roadType": "Urban"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 34,
    "description": "Warning of a priority road at reversed T-shaped crossing",
    "roadType": "Urban"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 35,
    "description": "Warning of a priority road at T-shaped crossing of fork type ahead",
    "roadType": "Urban"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 36,
    "description": "Warning of a priority road at reversed T-shaped crossing of fork type ahead",
    "roadType": "Urban"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 48,
    "description": "Warning of a clockwise roundabout ahead",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 49,
    "description": "Warning of a anti-clockwise roundabout ahead",
    "roadType": "Urban"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 61,
    "description": "Warning of a dangerous single curve to the right ahead",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 62,
    "description": "Warning of a dangerous single curve to the left ahead",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 74,
    "description": "Warning of a single right turn",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 75,
    "description": "Warning of a single left turn",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 87,
    "description": "Warning of a combination of two curves (right and left) ahead",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 88,
    "description": "Warning of a combination of two curves (left and left) ahead",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 11,
    "description": "Warning of dangerous double or multiple bends which start with a right curve",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 12,
    "description": "Warning of dangerous double or multiple bends which start with a left curve",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 24,
    "description": "Warning of a winding section, which starts with a right curve",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 25,
    "description": "Warning of a winding section, which starts with a left curve",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 36,
    "description": "Warning of the existence of a level railway crossing with gates or barrier ahead",
    "roadType": "Urban"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 37,
    "description": "Warning of a level railroad crossing point without gate or barrier ahead",
    "roadType": "Urban"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 38,
    "description": "Warning of a road with a tramway line ahead.",
    "roadType": "Urban"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 39,
    "description": "Warning of an area where children may exit from a school or playground frequently pass by and cross the road from the right side.",
    "roadType": "Urban"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 41,
    "description": "Warning of an area where pedestrians frequently pass by and cross the road from the right side",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 42,
    "description": "Warning of an area where elderly or disabled pedestrians likely pass by and cross the road ahead",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 44,
    "description": "Warning of a crosswalk where children may exit from a school or playground frequently pass by and cross the road from the left side.",
    "roadType": "Urban"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 45,
    "description": "Warning of a crosswalk where pedestrians frequently pass by and cross the road from the left side",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 46,
    "description": "Warning of a crosswalk where pedestrians frequently pass by and cross the road from the left side.",
    "roadType": "Urban"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 47,
    "description": "Warning of a crosswalk where pedestrians frequently pass by and cross the road from the right side.",
    "roadType": "Urban"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 53,
    "description": "Warning of a signal-controlled crossing ahead where signal(s) are invisible or hard to identify from a distant point.",
    "roadType": "Urban"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 54,
    "description": "Warning of a slippery road surface that may hinder travelling at normal speeds",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 55,
    "description": "Warning of the possibility of falling rocks or the presence of fallen rocks on road surface from the left side",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 56,
    "description": "Warning of an unpaved section with loose gravel on the surface that may hinder travelling at normal speeds",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 57,
    "description": "Warning of a dangerous road shoulder",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 58,
    "description": "Warning of the possibility of falling rocks or the presence of fallen rocks on road surface (right)",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 68,
    "description": "Warning of a bumpy section ahead that may hinder travelling at normal speeds.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 69,
    "description": "Warning of a road humps or series of road humps on road surface ahead that may hinder travelling at normal speeds",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 71,
    "description": "Warning of a section with a large single dip on road surface ahead that may hinder travelling at normal speeds",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 83,
    "description": "Warning of a merge of an inflow lane from the right into through traffic.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 85,
    "description": "Warning of a merge of an inflow lane from the left into through traffic.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 96,
    "description": "Warning that the number of lanes is reduced from the right",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 97,
    "description": "Warning that the number of lane is reduced from the left.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 3,
    "pictogramSerialNumber": 19,
    "description": "Warning that the width of the carriageway is reduced",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 3,
    "pictogramSerialNumber": 32,
    "description": "Warning that the approaching road is for two-way traffic.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 3,
    "pictogramSerialNumber": 46,
    "description": "Warning that there is a steep hill upwards with the indicated rate of incline (%)",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 3,
    "pictogramSerialNumber": 47,
    "description": "Warning that there is a steep dangerous hill downwards with the indicated rate of incline(%).",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 3,
    "pictogramSerialNumber": 48,
    "description": "Warning that there is a section under road construction or repairs ahead",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 3,
    "pictogramSerialNumber": 61,
    "description": "Warning of the possibility of sudden side wind that may hinder travelling at normal speeds from the left side",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 3,
    "pictogramSerialNumber": 62,
    "description": "Warning of the existence of low flying aircraft or sudden aircraft noise, (right)",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 3,
    "pictogramSerialNumber": 63,
    "description": "Warning of the possibility of wild animals crossing the road from the right hand side",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 3,
    "pictogramSerialNumber": 64,
    "description": "Warning of the possibility of domestic animal crossing the road from the right hand side",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 3,
    "pictogramSerialNumber": 65,
    "description": "Warning of the possibility of cross-wind, right.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "Airfield (left)",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 3,
    "pictogramSerialNumber": 66,
    "description": "Warning of the existence of low flying aircraft or sudden aircraft noise, (left)",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 3,
    "pictogramSerialNumber": 67,
    "description": "Warning of the possibility of wild animals, crossing from the left side.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 3,
    "pictogramSerialNumber": 68,
    "description": "Warning of the possibility of domestic animal crossing from the left side.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 3,
    "pictogramSerialNumber": 78,
    "description": "Warning of an opening or swing bridge ahead",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 3,
    "pictogramSerialNumber": 79,
    "description": "Warning of a quayside or river bank ahead",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 3,
    "pictogramSerialNumber": 81,
    "description": "Warning of a cycle route from the right side",
    "roadType": "Urban"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 3,
    "pictogramSerialNumber": 82,
    "description": "Warning of a cycle route from the left side",
    "roadType": "Urban"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 3,
    "pictogramSerialNumber": 95,
    "description": "Warning of a crossing where drivers must give way the right-of-way to the road ahead",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 3,
    "pictogramSerialNumber": 96,
    "description": "Warning of a crossing where drivers must give way the right-of-way to the road at specified distance ahead. A distance to the crossing from the sign is suffixed to this information.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 3,
    "pictogramSerialNumber": 97,
    "description": "Warning of an automatically deployed bollard that may rise from the road surface.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 3,
    "pictogramSerialNumber": 98,
    "description": "Warning of wrong way driving in the situation where drivers may misunderstand the direction for driving.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 3,
    "pictogramSerialNumber": 99,
    "description": "Warning of a crossing where drivers must stop ahead",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 11,
    "description": "Warning of a vertically long level-crossings ahead",
    "roadType": "Urban"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 12,
    "description": "Warning of a horizontally long level-crossings ahead",
    "roadType": "Urban"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 13,
    "description": "Warning of a vertically long level-crossings for two or more tracks ahead.",
    "roadType": "Urban"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 14,
    "description": "Warning of a horizontally long level-crossings for two or more tracks ahead.",
    "roadType": "Urban"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 15,
    "description": "Warning of an additional signs at an approaches to level-crossing or swing bridges.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 9,
    "pictogramSerialNumber": 98,
    "description": "Indicates auxiliary sign to alert",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "dangerWarning",
    "text": "traffic Sign + danger Warning",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 9,
    "pictogramSerialNumber": 99,
    "description": "Warning of some possible danger. This sign is used in case where no other Warning Signs are applicable",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 11,
    "description": "Notices that drivers must give way the right-of-traffic to oncoming traffic on a narrow section ahead",
    "roadType": "Urban"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 12,
    "description": "Notice of a point on the road where passing without stopping is prohibited",
    "roadType": "Urban"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 13,
    "description": "Notice of a point of the road where passing without stopping is prohibited at specified distance ahead. A distance to the crossing from the sign is suffixed to this information",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 14,
    "description": "Notices that a certain lane has been assigned for the priority use of light vehicles and motor cycles",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 15,
    "description": "Notices that specific goods vehicles (trucks) must use this lane",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 16,
    "description": "Notices that specific goods vehicles (trailers) must use this lane on the motorway",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 17,
    "description": "Notices that drivers must give way the right-of-way to the road",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 27,
    "description": "Notices that trailers must use this lane",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 28,
    "description": "Notices of lane for use by buses and cycles",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 29,
    "description": "Notices that a certain lane has been assigned for the exclusive use of fixed-route buses",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 31,
    "description": "Notices that a certain lane has been assigned for the priority use of fixed-route buses and pedal cycles",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 32,
    "description": "Notices that a certain lane has been assigned for the priority use of fixed-route buses.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 33,
    "description": "Notices that vehicle weighting over specified weight passing through the road is restricted",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 34,
    "description": "Notices that a lane is used for a high occupancy vehicle",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 54,
    "description": "Notice of dual carriageway ahead - notice of dividing of the carriageways which divides traffic directions onto separate carriageways",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 55,
    "description": "Notice of layout ahead to facilitate lane selection",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 56,
    "description": "Notice of the point to stop",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 57,
    "description": "Notice that mopeds (under 50cc) must dismount for right turn",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 58,
    "description": "Notice that mopeds (under 50cc) are prohibited to make two-step right turn",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 59,
    "description": "Notice that mopeds (under 50cc) must dismount for left turn",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 60,
    "description": "Notice that mopeds (under 50cc) are prohibited to make two-step left turn",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 76,
    "description": "Notice that any vehicles must reduce their speed in this zone",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 77,
    "description": "Notice that any vehicles must reduce their speed in this zone. A distance to the crossing from the sign is suffixed to this information",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 78,
    "description": "Notice that drivers on this road have priority to go through a crossroads which they are approaching.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 79,
    "description": "Notice of the end of priority road",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 80,
    "description": "Notice of the end of priority with distance to it",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 81,
    "description": "Notice of priority for oncoming traffic",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_DangerWarning.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 57,
    "description": "Notice that the maximum speed for motor vehicles is regulated",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 11,
    "description": "Indicates the existence/location of an information centre",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 12,
    "description": "Indicates the existence/location of a police office",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 13,
    "description": "Indicates facilities for wheelchair users",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 14,
    "description": "Indicates the existence/location of public telephone booths",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 15,
    "description": "Indicates the existence/location of a first-aid station",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 16,
    "description": "Indicates the location of a hospital",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 25,
    "description": "Indicates the existence/location of a maintenance service station",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 26,
    "description": "Indicates the existence/location of a breakdown service station",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 27,
    "description": "Indicates the place to inflate a tyre",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 28,
    "description": "Indicates the existence/location of a filing station",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 29,
    "description": "Indicates the existence/location of a filling station where LPG is available",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 45,
    "description": "Indicates the existence/location of a public lavatory",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 46,
    "description": "Indicates the existence /location of a restroom for wheel-chair user",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 47,
    "description": "Indicates the provision of a radio stations giving traffic information",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 48,
    "description": "Indicates the existence/location of a beach or swimming pool",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 61,
    "description": "Indicates the existence/location of a restaurant",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 62,
    "description": "Indicates the existence/location of a refreshment stand or cafeteria",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 63,
    "description": "Indicates the existence /location of a service area (with no description of services provided)",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 64,
    "description": "Indicates the existence /location of a postal office",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 81,
    "description": "Indicates the existence /location of a hotel or motel",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 82,
    "description": "Indicates that the Hotel/Motel is available for wheel-chair user",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 83,
    "description": "Indicates the existence/location of a youth hostel",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 84,
    "description": "Indicates the youth hostel is available for wheel chair user",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 85,
    "description": "Indicates the existence/location of a camping site",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 86,
    "description": "Indicates the existence/location of a caravan site.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 87,
    "description": "Indicates the existence/location of camping and caravan sites.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 88,
    "description": "Indicates the availability of facilities designed for the use of dumping wastes from recreational vehicle holding tank",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 89,
    "description": "Indicates the existence/location of a roadside picnic site",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 91,
    "description": "Indicates the existence/location of a playground for children",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 92,
    "description": "Indicates the existence/location of a starting point for walking",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 93,
    "description": "Indicates the existence/location of a relaxation area",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 94,
    "description": "Indicates the existence/location of a cross-country ski-ing site",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 95,
    "description": "Indicates the existence/location of a ski lift station",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 96,
    "description": "Indicates the existence/location of a cable car",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 98,
    "description": "Indicates the existence/location of a site for canoeing",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 99,
    "description": "Indicates the existence/location of cultural assets",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 11,
    "description": "Indicates the existence/location of natural assets",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 12,
    "description": "Indicates the existence/location of museum",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 13,
    "description": "Indicates the existence/location of a viewing site",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "publicFacilitiesPictogram",
    "serviceSubCategoryCode": "publicFacilities",
    "text": "Public Facilities",
    "icon": "PublicFacilities.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 14,
    "description": "Indicates the existence/location of a park",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "ambientOrRoadConditionPictogram",
    "serviceSubCategoryCode": "ambientCondition",
    "text": "Ambient Condition",
    "icon": "AmbientCondition.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 11,
    "description": "Warns that it is raining en route to a certain place",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "ambientOrRoadConditionPictogram",
    "serviceSubCategoryCode": "ambientCondition",
    "text": "Ambient Condition",
    "icon": "AmbientCondition.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 12,
    "description": "Warns that it is snowing or icing en route to a certain place",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "ambientOrRoadConditionPictogram",
    "serviceSubCategoryCode": "ambientCondition",
    "text": "Ambient Condition",
    "icon": "AmbientCondition.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 13,
    "description": "Warns that it is foggy en route to a certain place",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "ambientOrRoadConditionPictogram",
    "serviceSubCategoryCode": "ambientCondition",
    "text": "Ambient Condition",
    "icon": "AmbientCondition.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 14,
    "description": "Warns that visibility is poor due to snow, rain or fog",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "ambientOrRoadConditionPictogram",
    "serviceSubCategoryCode": "ambientCondition",
    "text": "Ambient Condition",
    "icon": "AmbientCondition.png",
    "pictogramCategoryCode": 3,
    "pictogramSerialNumber": 59,
    "description": "Warns that there is a strong side wind en route to a certain place",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "ambientOrRoadConditionPictogram",
    "serviceSubCategoryCode": "ambientCondition",
    "text": "Ambient Condition",
    "icon": "AmbientCondition.png",
    "pictogramCategoryCode": 3,
    "pictogramSerialNumber": 61,
    "description": "Warns of flooding en route to a certain place",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "ambientOrRoadConditionPictogram",
    "serviceSubCategoryCode": "roadCondition",
    "text": "Road Condition",
    "icon": "RoadCondition.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 11,
    "description": "Warns of flooding en route to a certain place",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "ambientOrRoadConditionPictogram",
    "serviceSubCategoryCode": "roadCondition",
    "text": "Road Condition",
    "icon": "RoadCondition.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 12,
    "description": "Warns that an accident has occurred ahead",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "ambientOrRoadConditionPictogram",
    "serviceSubCategoryCode": "roadCondition",
    "text": "Road Condition",
    "icon": "RoadCondition.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 13,
    "description": "Warns that there is a vehicle on fire ahead",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "ambientOrRoadConditionPictogram",
    "serviceSubCategoryCode": "roadCondition",
    "text": "Road Condition",
    "icon": "RoadCondition.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 14,
    "description": "Warns that there are shed load(s) ahead",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "ambientOrRoadConditionPictogram",
    "serviceSubCategoryCode": "roadCondition",
    "text": "Road Condition",
    "icon": "RoadCondition.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 15,
    "description": "Warns that there is a slow-moving maintenance vehicle ahead",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "ambientOrRoadConditionPictogram",
    "serviceSubCategoryCode": "roadCondition",
    "text": "Road Condition",
    "icon": "RoadCondition.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 16,
    "description": "Warns that there is a breakdown vehicle standing still ahead",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 11,
    "description": "Advance direction signs - Notice of direction(s) and destination(s) on the next junction with indications of the junction layout, place name(s) and the distance(s) from there, if any.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 12,
    "description": "Advance sign showing the direction of places - Notice of direction(s) and destination(s) on the next junction with indications of the junction layout, place name(s) and the distance(s) from there, if any.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 21,
    "description": "Notice of direction(s) to be followed at a situation where a left (or right) turn at the next intersection is prohibited",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 33,
    "description": "Notice of direction(s) and destination(s) on the next junction with indications of the junction layout, place name(s) and the distance(s) from there, if any",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 68,
    "description": "Notice of a motorway entrance with indications of the place name and a distance to the entrance from the present location",
    "roadType": "Motorway"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 69,
    "description": "Notice of a motorway exit with indications of the exit number, destination(s) connecting road number(s)/ name(s) and/or distance(s) from there, if any",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 71,
    "description": "Notice of a motorway exit with indications of the exit number, destination(s), connecting road number(s)/name(s) and/or distance(s) from there, if any.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 72,
    "description": "Notice of a motorway exit with indications of the exit number, destinations), connecting road number (s)/ name(s) and/or distance(s) from there, if any",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 73,
    "description": "Notice of a service area on motorway with indications of type of facility and a distance to the Service Area from the present location",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 74,
    "description": "Notice of end of motorway",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 84,
    "description": "Notice of the destination information with indications of place name(s) and distance(s) to destination(s).",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 85,
    "description": "Notice of the destination information selectable at a junction with indications of place name(s) and dis-tance(s) to destination(s)",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 86,
    "description": "Notice of the destination information selectable at a junction with indications of road number(s) and dis-tance(s) to destination(s)",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 87,
    "description": "Notice of the destination information on the following road with indications of place name(s) and distance(s) to destination(s)",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 88,
    "description": "Notice of the destination information selectable at a junction with indications of the junction layout, place names) and distances) to destinations).",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 1,
    "pictogramSerialNumber": 89,
    "description": "Notice of the destination information selectable at a junction with indications of the junction layout, place name(s) and distance(s) to destination(s)",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 22,
    "description": "Notice of the direction of a motorway entrance with indication of the motorway name",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 23,
    "description": "Notice of the direction of a motorway exit with indications of place name(s), road number(s) and exit number",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 24,
    "description": "Notice of the direction of a motorway exit with indications of place name and exit number",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 25,
    "description": "Notice of the destination of a lane to be taken",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 2,
    "pictogramSerialNumber": 26,
    "description": "Notice of the direction of a service area on the motorway with indication of the type of facility",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 3,
    "pictogramSerialNumber": 26,
    "description": "Notice of the road number of a national/interstate highway",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 3,
    "pictogramSerialNumber": 27,
    "description": "Notice of the road number of a local highway",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 25,
    "description": "Notice of dead-end of the road ahead",
    "roadType": "Urban"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 26,
    "description": "Notice of dead-end of the connecting road at the next junction",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 38,
    "description": "Notices that the following direction has the right-of-way over oncoming traffic on narrow sections ahead",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 39,
    "description": "Notices that access is permitted",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 11,
    "description": "Number and direction of traffic lanes",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 12,
    "description": "Notices that the left lane ends at a point ahead",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 13,
    "description": "Notices that the lane diverges at a point ahead",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 14,
    "description": "Notice that trucks prohibited from this lane",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 15,
    "description": "Notice that trucks prohibited from main lanes",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 16,
    "description": "Notice that trucks prohibited from diverging lane",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 17,
    "description": "Notice of route to be followed",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 18,
    "description": "Notice that route detour ahead",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 19,
    "description": "Notice of crawler lane",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 21,
    "description": "Notice of an escape lane right ahead",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 22,
    "description": "Notice of an escape lane left ahead",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 23,
    "description": "Notice of one-way street ahead",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 24,
    "description": "Notice of lane convergence ahead",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 43,
    "description": "Notice that directional flow is allowed ahead",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 44,
    "description": "Notice that lane converges and directional flow is allowed ahead",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 45,
    "description": "Notice of start of fourth peak time hour lane ahead",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 46,
    "description": "Notice of end of peak time lane ahead",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 47,
    "description": "Notice of end of fourth peak time at lane ahead",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 48,
    "description": "Notice that peak time lane ends and converges",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 49,
    "description": "Notice that forth peak time lane ends and converges",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 51,
    "description": "Notice of closure of a traffic lane, two lanes to one (right lane ends)",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 52,
    "description": "Notice of closure of a traffic lane, three lanes to two (right lane ends)",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 53,
    "description": "Notice of lane convergence, four lanes to three (right lane ends)",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 54,
    "description": "Notice of closure of a traffic lane, two lanes to one (left lane ends)",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 55,
    "description": "Notice of closure of a traffic lane, three lanes to two (left lane ends)",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 56,
    "description": "Notice of closure of a traffic lane, four lanes to three (left lane ends)",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 57,
    "description": "Notice that two adjacent traffic lanes are separated. The right-hand lane moves to the right, but remains within the same carriageway with width restriction on right hand lane",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 58,
    "description": "Notice that two traffic lanes move to the right: one crosses over to the other carriageway. The advised speed where the lanes divert is specified",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 59,
    "description": "Notice of lane closure",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 60,
    "description": "Notice that a lane is free",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 61,
    "description": "Notice of clear lane to left",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 62,
    "description": "Notice of clear lane to right",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 63,
    "description": "Notice of end of all restrictions by electronic signs",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 64,
    "description": "Notice that compulsory minimum speed is applied to different lanes",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 65,
    "description": "Notice that compulsory minimum speed is applied to one lane",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 66,
    "description": "Notice that speed limit is applied to different lanes",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 67,
    "description": "Notice that lanes are reserved for buses",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 68,
    "description": "Notice of one-way ahead",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 69,
    "description": "Notice of preselection ahead",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 11,
    "description": "Notice of road for motor vehicles",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 12,
    "description": "Notice of end of road for motor vehicles",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 13,
    "description": "Notice of a tunnel ahead",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 14,
    "description": "Notice of the end of tunnel",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 15,
    "description": "Notice of a pedestrian crossing",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 21,
    "description": "Notice of a safety evacuation area",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 22,
    "description": "Notice of an emergency exit",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 23,
    "description": "Notice of an extinguisher",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 24,
    "description": "Notice of an emergency roadside telephone",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 25,
    "description": "Notice of an emergency call button",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 26,
    "description": "Notice of an emergency stopping place",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 27,
    "description": "Notice of a waiting area for slower traffic",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 28,
    "description": "Notice of a safety zone",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 29,
    "description": "Notice of a hospital",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 31,
    "description": "Notice of a toll gate",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 32,
    "description": "Notice of a toll zone",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 33,
    "description": "Notice of an automatic toll gate",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 34,
    "description": "Notice of a manual toll gate",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 35,
    "description": "Notice of a zone for bicycles",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 36,
    "description": "Notice of an airport",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 37,
    "description": "Notice of airport departure area",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 38,
    "description": "Notice of airport arrival area",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 39,
    "description": "Notice of heliport",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 41,
    "description": "Notice of railway station.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 43,
    "description": "Notice of car rental facility",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 44,
    "description": "Notice of taxi stop",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 45,
    "description": "Notice of bus stop",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 46,
    "description": "Notice of tramway stop",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 47,
    "description": "Notice of road open or closed",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 51,
    "description": "Notice of a parking area",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 52,
    "description": "Notice of a machine-charged parking",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 53,
    "description": "Notice of a park-and-ride point",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 54,
    "description": "Notice of a car ferry port",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 55,
    "description": "Notice of car train area",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 61,
    "description": "Notice of beginning of a built-up area",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 62,
    "description": "Notice of end of a built-up area",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 65,
    "description": "Notice of a parking zone",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 66,
    "description": "Notice of a maximum speed zone",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 69,
    "description": "Notice of end of parking zone",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 71,
    "description": "Notice of end of maximum speed zone",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 72,
    "description": "Notice of a residential area",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 73,
    "description": "Notice of end of residential area",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 81,
    "description": "Notice of direction of an airfield/airport",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 82,
    "description": "Notice of direction of a camping site",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 83,
    "description": "Notice of direction of a youth hostel",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 84,
    "description": "Notice of direction of public transport",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 85,
    "description": "Notice of confirmatory signs",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 86,
    "description": "Notice that chains or snow tyres recommended",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 87,
    "description": "Notice of advisory speed",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 89,
    "description": "Notice of advised itinerary for heavy vehicles",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 91,
    "description": "Notice of a pedestrian overpass or underpass without steps",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 92,
    "description": "Notice of a pedestrian overpass or underpass with steps",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 93,
    "description": "Notice of an exit from a motorway",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 94,
    "description": "Notice of direction and distance of the nearest emergency exits",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 95,
    "description": "Notice of a parking space reserved for handicapped persons",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "informative",
    "text": "traffic Sign + informative",
    "icon": "TrafficSign_Informative.png",
    "pictogramCategoryCode": 8,
    "pictogramSerialNumber": 96,
    "description": "Notice of the intersection utilising broad strokes that indicate priority roads over lower priority roads indicated by narrower strokes.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 11,
    "description": "Notice that all vehicles/mopeds, cycles/pedestrians are forbidden to enter the road.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 12,
    "description": "Notice that motor vehicles are forbidden to enter the road.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 13,
    "description": "Notice that motor vehicles are forbidden to enter in the designated direction of the road.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 14,
    "description": "Notice that vehicles crossing except for vehicles turning left are forbidden to enter or exit the road.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 15,
    "description": "Notice that motor vehicles are forbidden to pass through the designated section of the road.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 16,
    "description": "Notice that motor vehicles except two wheeled motor cycles without a side-car are forbidden to pass through the designated section of the road.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 17,
    "description": "Notice that motorcycles are forbidden to pass through the designated section of the road.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 18,
    "description": "Notice that cycles are forbidden to pass through the designated section of the road.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 19,
    "description": "Notice that mopeds are forbidden to pass through the designated section of the road.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 21,
    "description": "Notice that goods vehicles are forbidden to pass through the designated section of the road.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 22,
    "description": "Notice that motor vehicles with a trailer are forbidden to pass through the designated section of the road.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 23,
    "description": "Notice that pedestrians are forbidden to pass through the designated section of the road",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 24,
    "description": "Notice that animal-drawn vehicles are forbidden to pass through the designated section of the road.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 25,
    "description": "Notice that handcarts or other vehicles without motors except bicycles are forbidden to pass through the designated section of the road.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 26,
    "description": "Notice that agricultural motor vehicles are forbidden to pass through the designated section of the road.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 27,
    "description": "Notice that buses are forbidden to pass through the designated section of the road.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 29,
    "description": "Notice that power driven vehicles drawing a trailer other than a semi-trailer or a single axle trailer are forbidden to pass through the desig-nated section of the road.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 31,
    "description": "Notice that a horses ridden or not are forbidden to pass through the designated section of the road.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 33,
    "description": "Notice that vehicles that cannot exceed spec-ified speed are forbidden to pass through the designated section of the road",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 34,
    "description": "Notice that power driven vehicles drawing any trailers are forbidden to pass through the designated section of the road.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 51,
    "description": "Notice that motor vehicles whose classes are specified on the sign are forbidden to pass through the designated section of the road.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 52,
    "description": "Notice that motor or animal-drawn vehicles whose classes are specified on the sign are forbidden to pass through the designated section of the road.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 53,
    "description": "Notice that goods vehicles and buses are forbidden to pass through the designated section of the road.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 54,
    "description": "Notice that bicycles, mopeds and disabled drivers vehicles are forbidden to pass through the designated section of the road.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 55,
    "description": "Notice that vehicles that are not able or allowed to go faster than specified speed, no microcars bikes, mopeds and disabled drivers' mobility vehicles are forbidden to pass through the designated section of the road.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 75,
    "description": "Notice that motor vehicles carrying hazardous goods are forbidden to pass through the designated section of the road.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 76,
    "description": "Notice that vehicles carrying dangerous goods is required are which special sign plating forbidden to pass through the designated section of the road.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 77,
    "description": "Notice that vehicles carrying more than a certain quantity of substances liable to cause water pollution are forbidden to pass through the designated section of the road",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 4,
    "pictogramSerialNumber": 99,
    "description": "Notice that motor vehicles with more than the specified width are forbidden to pass through the designated section of the road.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 11,
    "description": "Notice that motor vehicles with more than the specified height are forbidden to pass through exceeding the designated section of the road.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 12,
    "description": "Notice that motor vehicles with more than the specified weight are forbidden to pass through the designated section of the road",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 13,
    "description": "Notice that motor vehicles with more than the specified axle weight are forbidden to pass through the designated section of the road.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 14,
    "description": "Notice that motor vehicles with more than the specified length are forbidden to pass through the designated section of the road.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 15,
    "description": "Notice that drivers must maintain the specified distance from the preeeding vehicle when driving in the designated section of the road.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 27,
    "description": "Notice that motor vehicles are forbidden to turn left.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 28,
    "description": "Notice that motor vehicles are forbidden to turn right.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 29,
    "description": "Notice that motor vehicles are forbidden to make U-turn.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 42,
    "description": "Notice that motor vehicles are forbidden over-take other vehicles.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 44,
    "description": "Notice that motor vehicles are forbidden to overtake goods vehicles.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 57,
    "description": "Notice that the maximum speed for motor vehi-cles is regulated.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 58,
    "description": "Notice that motor vehicle operators from blowing the horns for warning when passing through the designated section of the road is prohibited.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 61,
    "description": "Notice of compulsory stop for customs check.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 62,
    "description": "Notice of compulsory stop for military police check.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 63,
    "description": "Notice of compulsory stop for police check",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 64,
    "description": "Notice of compulsory stop for toll collection",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 65,
    "description": "Notice that pedestrians are prohibited from crossing the road.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 77,
    "description": "Notice that motor vehicles are forbidden to park in the designated section of the road.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 78,
    "description": "Notice that motor vehicles are forbidden to park or stop still in the designated section of the road.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 79,
    "description": "Notice that motor vehicles are allowed to park from the 1st to 15th of each month.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 81,
    "description": "Notice that motor vehicles are allowed to park from the 16th to 31st of each month.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 92,
    "description": "Notice that the use of parking area is permitted in case of emergency.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 93,
    "description": "Notice that limited parking times and/or duration is applicable.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 94,
    "description": "Notice that limited parking times and/or dura-tion is not applicable.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 95,
    "description": "Notice that zone in which parking is prohibited.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 96,
    "description": "Notice that zone in which parking is prohibited at certain times zone in which parking is prohibited at certain times.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 97,
    "description": "Notice that end of zone in which parking is prohibited.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 5,
    "pictogramSerialNumber": 98,
    "description": "Notice that end of zone in which parking is pro-hibited at certain times zone in which parking is prohibited at certain times.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 11,
    "description": "Notice of the end of all local prohibition imposed on moving vehicles.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 12,
    "description": "Notice of the end of prohibition of overtaking.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 13,
    "description": "Notice of the end of prohibition of overtaking for goods vehicles",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 14,
    "description": "Notice of the end of speed limit.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 15,
    "description": "Notice of the end of compulsory minimum speed",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 16,
    "description": "Notice of a general speed limit in force in a country or in a subdivision of that country.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 21,
    "description": "Notice that vehicles can drive on tram track.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 22,
    "description": "Notice that stopping is permitted in the zone.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 31,
    "description": "Notice of Distance to regulated road or of the zone.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 32,
    "description": "Notice of length of the regulated dangerous section of road or of the zone.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 33,
    "description": "Notice of prohibition begins at this point.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 34,
    "description": "Notice of prohibition repeats at this point.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 35,
    "description": "Notice of prohibition ends at this point",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 36,
    "description": "Notice of regulation for particular road users",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 6,
    "pictogramSerialNumber": 37,
    "description": "Notice of regulation exception for a certain category of road users.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 7,
    "pictogramSerialNumber": 11,
    "description": "Travel to the left direction only",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 7,
    "pictogramSerialNumber": 12,
    "description": "Travel to the right direction only",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 7,
    "pictogramSerialNumber": 13,
    "description": "Travel straight ahead",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 7,
    "pictogramSerialNumber": 14,
    "description": "Right turn only",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 7,
    "pictogramSerialNumber": 15,
    "description": "Left turn only.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 7,
    "pictogramSerialNumber": 16,
    "description": "Straight ahead or right turn only",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 7,
    "pictogramSerialNumber": 17,
    "description": "Straight Ahead or left turn only.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 7,
    "pictogramSerialNumber": 18,
    "description": "Pass to the right",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 7,
    "pictogramSerialNumber": 19,
    "description": "Pass to the left",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 7,
    "pictogramSerialNumber": 21,
    "description": "Right turn or left turn only",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 7,
    "pictogramSerialNumber": 23,
    "description": "Pass either side",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 7,
    "pictogramSerialNumber": 24,
    "description": "Compulsory direction for vehicles carrying dangerous goods",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 7,
    "pictogramSerialNumber": 43,
    "description": "Notice of two-way traffic ahead",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 7,
    "pictogramSerialNumber": 44,
    "description": "Notice of one-way road to the right ahead",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 7,
    "pictogramSerialNumber": 45,
    "description": "Notice of one-way road to the left ahead",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 7,
    "pictogramSerialNumber": 46,
    "description": "Notice of a clockwise roundabout ahead",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 7,
    "pictogramSerialNumber": 47,
    "description": "Notice of an anti-clockwise roundabout ahead.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 7,
    "pictogramSerialNumber": 48,
    "description": "Notice that the road from here is only for motor vehicles.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 7,
    "pictogramSerialNumber": 49,
    "description": "Notice that the path or track is compulsory for cycles",
    "roadType": "Urban"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 7,
    "pictogramSerialNumber": 51,
    "description": "Notice that a riding abreast is permitted.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 7,
    "pictogramSerialNumber": 52,
    "description": "Notice of the zone where bicycles cross",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 7,
    "pictogramSerialNumber": 53,
    "description": "Notice that the path or track is for compulsory for pedestrians",
    "roadType": "Urban"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 7,
    "pictogramSerialNumber": 54,
    "description": "Notice that the path or track is for compulsory for horseback riders.",
    "roadType": "Urban"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 7,
    "pictogramSerialNumber": 72,
    "description": "Notice of the zone where pedestrians and bicycles cross.",
    "roadType": ""
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 7,
    "pictogramSerialNumber": 73,
    "description": "Notice of that the path or track is only for pedestrians and cyclists.",
    "roadType": "Urban"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 7,
    "pictogramSerialNumber": 95,
    "description": "Notice that the minimum speed of motor vehicles when travelling is regulated.",
    "roadType": "All"
  },
  {
    "serviceCategoryCode": "trafficSignPictogram",
    "serviceSubCategoryCode": "regulatory",
    "text": "traffic Sign + regulatory",
    "icon": "TrafficSign_Regulation.png",
    "pictogramCategoryCode": 7,
    "pictogramSerialNumber": 96,
    "description": "Notice that motor vehicles to have snow chains fitted is mandated.",
    "roadType": "All"
  }
]
