import { useLocalization } from '@fluent/react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';
import { AlertIcon } from '../../../../icons';
import styles from '../styles';

interface SubmitModalProps {
  open: boolean;
  onConfirm: () => void;
  onNavigateToSettings: () => void;
}

const SubmitModal: React.FC<SubmitModalProps> = ({ open, onConfirm, onNavigateToSettings }) => {
  const { l10n } = useLocalization();
  const classes = styles();

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title">
      <Box className={classes.titleBox}>
        <AlertIcon />
        <DialogTitle id="form-dialog-title">
          {l10n.getString('forms-ivim-modal-submit-warning-title')}
        </DialogTitle>
      </Box>
      <DialogContent>
        <DialogContentText>
          {l10n.getString('forms-ivim-modal-submit-warning-text')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onNavigateToSettings} color="secondary">
          {l10n.getString('forms-ivim-modal-submit-warning-btn-settings')}
        </Button>
        <Button onClick={onConfirm} color="primary">
          {l10n.getString('forms-ivim-modal-submit-warning-btn-submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubmitModal;
