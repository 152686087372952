import { useLocalization } from '@fluent/react';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from '@material-ui/core';
import RotateLeftRoundedIcon from '@material-ui/icons/RotateLeftRounded';
import { RootState, useAppDispatch } from '_store';
import ivimsReducer from '_store/ivim/ivimsReducers';
import { IvimDateFilterType, IvimStatusFilter } from '_store/ivim/ivimsTypes';
import {
  ivimFilterCreatedFromSelector,
  ivimFilterCreatedToSelector,
  ivimFilterDateSelector,
  ivimFilterServiceCategoryCodeSelector,
  ivimFilterServiceSubCategoryCodeSelector,
  ivimFilterStatusSelector,
} from '_store/ivim/selectors';
import { selectedRoadSegmentIdsSelector } from '_store/roads/selectors';
import { DatePeriodEnum } from '_store/utils';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import DateItem from '../../../../../components/items/DateItem';
import SelectItem from '../../../../../components/items/SelectItem';
import * as ivimEvents from '../../../../../assets/ivimEvents.json';

interface PopoverFiltersProps {
  setChecked: (checked: string[]) => void;
  setFilteredStatus: (status: IvimStatusFilter | '') => void;
}

export const PopoverFilters = ({ setChecked, setFilteredStatus }: PopoverFiltersProps) => {
  const { l10n } = useLocalization();
  const dispatch = useAppDispatch();

  const ivimStatus = useSelector(ivimFilterStatusSelector);
  const ivimsDate = useSelector(ivimFilterDateSelector);
  const ivimsCreatedFrom = useSelector(ivimFilterCreatedFromSelector);
  const ivimsCreatedTo = useSelector(ivimFilterCreatedToSelector);
  const ivimServiceCategoryCode = useSelector(ivimFilterServiceCategoryCodeSelector);
  const ivimServiceSubCategoryCode = useSelector(ivimFilterServiceSubCategoryCodeSelector);
  const selectedRoadSegmentIds: string[] = useSelector(selectedRoadSegmentIdsSelector);
  const ivimDateFilterType = useSelector((state: RootState) => state.ivims.dateFilterType);

  const { busy } = useSelector((state: RootState) => state.application.activeDialog);

  const [dateRangeType, setDateRangeType] = useState(ivimDateFilterType);
  const [filters, setFilters] = useState({
    status: ivimStatus,
    date: ivimsDate,
    createdFrom: ivimsCreatedFrom,
    createdTo: ivimsCreatedTo,
    serviceCategoryCode: ivimServiceCategoryCode,
    serviceSubCategoryCode: ivimServiceSubCategoryCode,
  });

  const serviceCategoryCode = [...new Set(ivimEvents.map((i) => i.serviceCategoryCode))];

  const serviceSubCategoryCode =
    filters.serviceCategoryCode === null
      ? []
      : [
          ...new Set(
            ivimEvents
              .filter((ev) => ev.serviceCategoryCode === filters.serviceCategoryCode)
              .map((ev) => ev.serviceSubCategoryCode),
          ),
        ];

  const statusOptions = useMemo(
    () => [
      { value: IvimStatusFilter.IN_PROGRESS, labelKey: 'forms-event-status-events-prog' },
      { value: IvimStatusFilter.CLOSED, labelKey: 'forms-event-status-events-clos' },
      { value: IvimStatusFilter.DISABLED, labelKey: 'forms-event-status-events-disabled' },
      { value: IvimStatusFilter.SCHEDULED, labelKey: 'forms-event-status-events-scheduled' },
    ],
    [],
  );

  const datePeriodOptions = useMemo(
    () => [
      { value: DatePeriodEnum.lastHour, labelKey: 'forms-event-date-last-hour' },
      { value: DatePeriodEnum.lastDay, labelKey: 'forms-event-date-last-day' },
      { value: DatePeriodEnum.lastWeek, labelKey: 'forms-event-date-last-week' },
      { value: DatePeriodEnum.lastMonth, labelKey: 'forms-event-date-last-month' },
      { value: DatePeriodEnum.last3Months, labelKey: 'forms-event-date-last-3-months' },
      { value: DatePeriodEnum.last6Months, labelKey: 'forms-event-date-last-6-months' },
      { value: DatePeriodEnum.lastYear, labelKey: 'forms-event-date-last-year' },
    ],
    [],
  );

  const updateFiltersAndDispatch = useCallback(
    (newFilters) => {
      setChecked([]);
      setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
      dispatch(
        ivimsReducer.actions.ivimsRequest({
          status: newFilters.status !== undefined ? newFilters.status : filters.status,
          roadSegmentIds: selectedRoadSegmentIds,
          date: newFilters.date || filters.date,
          createdFrom:
            newFilters.createdFrom !== undefined ? newFilters.createdFrom : filters.createdFrom,
          createdTo: newFilters.createdTo !== undefined ? newFilters.createdTo : filters.createdTo,
          currentPage: 1,
          itemsPerPage: 10,
          serviceCategoryCode:
            newFilters.serviceCategoryCode !== undefined
              ? newFilters.serviceCategoryCode
              : filters.serviceCategoryCode,
          serviceSubCategoryCode:
            newFilters.serviceSubCategoryCode !== undefined
              ? newFilters.serviceSubCategoryCode
              : filters.serviceSubCategoryCode,
        }),
      );
    },
    [dispatch, filters, selectedRoadSegmentIds, setChecked],
  );

  const filterByStatus = (ev: any) => {
    const status = ev.target.value as IvimStatusFilter;
    updateFiltersAndDispatch({ status });
    setFilteredStatus(status);
  };

  const filterByDatePeriod = (ev: any) => {
    const date = ev.target.value as DatePeriodEnum;
    updateFiltersAndDispatch({ date, createdFrom: null, createdTo: null });
  };

  const filterCreatedTo = (date: Date) => {
    updateFiltersAndDispatch({ createdTo: date });
  };

  const filterCreatedFrom = (date: Date) => {
    updateFiltersAndDispatch({ createdFrom: date });
  };

  const handleDateFilterRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as IvimDateFilterType;
    setDateRangeType(value);
    dispatch(ivimsReducer.actions.changeDateFilterType(value));
  };

  const filterServiceCategoryCode = (ev: any) => {
    updateFiltersAndDispatch({
      serviceCategoryCode: ev.target.value,
      serviceSubCategoryCode: null,
    });
  };

  const filterServiceSubCategoryCode = (ev: any) => {
    updateFiltersAndDispatch({ serviceSubCategoryCode: ev.target.value });
  };

  const resetStatusFilter = () => {
    updateFiltersAndDispatch({ status: '' });
    setFilteredStatus('');
  };

  const resetServiceCategoryAndSubcategory = () => {
    setChecked([]);
    updateFiltersAndDispatch({
      serviceCategoryCode: null,
      serviceSubCategoryCode: null,
    });
  };

  return (
    <>
      <Box style={{ display: 'flex' }} marginBottom={2}>
        <SelectItem
          text={l10n.getString('forms-event-filter-status')}
          value={filters.status ?? ''}
          options={statusOptions.map(({ value, labelKey }) => ({
            value,
            label: l10n.getString(labelKey),
          }))}
          onChange={filterByStatus}
          tooltip={l10n.getString('forms-event-filter-status')}
          style={{ width: '100%', minWidth: 150 }}
          formControlStyle={{ marginTop: 0 }}
        />
        <Tooltip title={l10n.getString('forms-ivim-filters-reset-status')} placement="top">
          <RotateLeftRoundedIcon
            onClick={resetStatusFilter}
            style={{ cursor: 'pointer', marginTop: 20, marginLeft: 10 }}
          />
        </Tooltip>
      </Box>
      <Box style={{ display: 'flex', justifyContent: 'space-between' }} marginBottom={2}>
        <FormControl component="fieldset">
          <FormLabel component="legend">{l10n.getString('forms-event-filter-by-date')}</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={dateRangeType}
            onChange={handleDateFilterRadio}
            row
          >
            <FormControlLabel
              value={IvimDateFilterType.RELATIVE}
              control={<Radio color="primary" />}
              label={l10n.getString('forms-event-filter-by-date-relative-range')}
            />
            <FormControlLabel
              value={IvimDateFilterType.ABSOLUTE}
              control={<Radio color="primary" />}
              label={l10n.getString('forms-event-filter-by-date-absolute-range')}
              color="primary"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      {dateRangeType === 'relative' && (
        <Box
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 8,
            marginBottom: 16,
          }}
        >
          <SelectItem
            text={l10n.getString('forms-event-filter-date')}
            value={filters.date ?? ''}
            options={datePeriodOptions.map(({ value, labelKey }) => ({
              value,
              label: l10n.getString(labelKey),
            }))}
            onChange={filterByDatePeriod}
            tooltip={l10n.getString('forms-event-filter-date')}
            style={{ gridColumn: 1 / 2 }}
            formControlStyle={{ marginTop: 0 }}
          />
        </Box>
      )}
      {dateRangeType === 'absolute' && (
        <Box
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 8,
            marginBottom: 16,
            marginTop: 16,
          }}
        >
          <DateItem
            label={l10n.getString('forms-event-created-from')}
            value={filters.createdFrom}
            disabled={busy}
            onChange={filterCreatedFrom}
            tooltip={l10n.getString('forms-event-created-from-tooltip')}
            disablePast={false}
            maxDate={filters.createdTo ?? new Date()}
          />
          <DateItem
            label={l10n.getString('forms-event-created-to')}
            value={filters.createdTo}
            disabled={busy}
            onChange={filterCreatedTo}
            tooltip={l10n.getString('forms-event-created-to-tooltip')}
            disablePast={false}
            minDate={filters.createdFrom ?? new Date()}
          />
        </Box>
      )}
      <Box style={{ display: 'grid', gridTemplateColumns: '7fr 7fr 1fr', gap: 8 }}>
        <SelectItem
          text={l10n.getString('forms-ivim-ser-cat-cod')}
          options={serviceCategoryCode.map((n) => ({
            value: n,
            label: l10n.getString(`forms-ivim-ser-cat-cod-enum-${n}`, {
              defaultValue: n,
            }),
          }))}
          disabled={busy}
          value={filters.serviceCategoryCode ?? ''}
          onChange={filterServiceCategoryCode}
          tooltip={l10n.getString('forms-ivim-sv-cat-code-tp')}
        />

        <SelectItem
          text={l10n.getString('forms-ivim-ser-sub-cat-cod')}
          options={serviceSubCategoryCode.map((n) => ({
            value: n,
            label: l10n.getString(`forms-ivim-ser-cat-sub-cod-enum-${n}`, {
              defaultValue: n,
            }),
          }))}
          disabled={filters.serviceCategoryCode === null || busy}
          value={filters.serviceSubCategoryCode ?? ''}
          onChange={filterServiceSubCategoryCode}
          tooltip={l10n.getString('forms-ivim-sv-sub-cat-code-tp')}
        />
        <Tooltip title={l10n.getString('forms-denm-filters-reset-causes')} placement="top">
          <RotateLeftRoundedIcon
            onClick={resetServiceCategoryAndSubcategory}
            style={{ cursor: 'pointer', marginTop: 40, marginLeft: 10 }}
          />
        </Tooltip>
      </Box>
    </>
  );
};
