import { useLocalization } from '@fluent/react';
import { Box, Button, CardContent, CardHeader, IconButton } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ivimFormReducer from '_store/forms/manage-ivim';
import ivimsReducer from '_store/ivim/ivimsReducers';
import { IvimStatusFilter } from '_store/ivim/ivimsTypes';
import { selectedRoadSegmentIdsSelector } from '_store/roads/selectors';
import CreateIvim from './CreateIvim';
import ManageIvim from './ManageIvim';
import styles from './styles';
import { IvimType, IvimTypeEnum } from './types';
import { getFromLocalStorage } from '../../../../utils';
const IvimCrudLayout: FC<IvimType> = ({ id, type, onGoingBack, onClose, closeDialog }) => {
  const { l10n } = useLocalization();
  const classes = styles();
  const selectedRoadSegmentIds: string[] = useSelector(selectedRoadSegmentIdsSelector);

  let LayoutType = null;
  let CardHeaderTitle = null;

  const dispatch = useDispatch();
  useEffect(() => {
    if (type === IvimTypeEnum.NEW_IVIM) {
      const savedFormState = getFromLocalStorage('tempIvimFormState');

      if (!savedFormState) {
        dispatch(ivimFormReducer.actions.resetForm());
      }

      dispatch(
        ivimsReducer.actions.ivimsRequest({
          roadSegmentIds: selectedRoadSegmentIds,
          status: IvimStatusFilter.IN_PROGRESS,
        }),
      );
    }
  }, [type]);

  switch (type) {
    case IvimTypeEnum.NEW_IVIM:
      CardHeaderTitle = l10n.getString('forms-ivim-add-new');
      LayoutType = <CreateIvim closeDialog={closeDialog} />;
      break;
    case IvimTypeEnum.UPDATE_IVIM: {
      CardHeaderTitle = `${l10n.getString('forms-ivim-update-ivim')}`;
      LayoutType = <ManageIvim id={id} type={type} closeDialog={closeDialog} />;
      break;
    }
    case IvimTypeEnum.CLONE_IVIM: {
      CardHeaderTitle = `${l10n.getString('forms-ivim-clone-ivim')}`;
      LayoutType = <ManageIvim id={id} type={type} closeDialog={closeDialog} />;
      break;
    }
    case IvimTypeEnum.DELETE_IVIM:
      LayoutType = null;
      break;

    case IvimTypeEnum.DISABLE_IVIM:
      LayoutType = null;
      break;

    default:
      CardHeaderTitle = `Add a new IVIM`;
      LayoutType = null;
  }

  return (
    <CardContent className={classes.cardContent}>
      <Box className={classes.box}>
        <Button
          variant="text"
          onClick={onGoingBack}
          size="small"
          className={classes.backBnt}
          startIcon={<ArrowBackIosIcon />}
        >
          {l10n.getString('back')}
        </Button>

        <IconButton className={classes.closeBnt} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <CardHeader
        title={CardHeaderTitle}
        className={classes.header}
        titleTypographyProps={{ variant: 'h6' }}
      />

      {LayoutType}
    </CardContent>
  );
};

export default React.memo(IvimCrudLayout);
