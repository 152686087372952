import { useLocalization } from '@fluent/react';
import {
  Box,
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { Coordinate, CreateDENMPayload, RelevanceDistance } from '_lib/api';
import { RootState, useAppDispatch } from '_store';
import appReducer from '_store/application/reducer';
import {
  DialogType,
  MapElementType,
  PointCount,
  PointSelectionParams,
  PointSelectionType,
} from '_store/application/types';
import denmsReducer from '_store/denm/denmsReducers';
import {
  causesSelector,
  denmCauseRoadWorksSelector,
  subCausesByCauseSelector,
} from '_store/denm/selectors';
import denmFormReducer, { CreateDENMForm } from '_store/forms/manage-denm';
import isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';

import { extraSelector, extraTraceMapElementsSelector } from '_store/application/selectors';
import {
  adverseWeatherConditions,
  checkValidityPeriod,
  convertToMilliseconds,
} from '_store/denm/utils';
import { Waypoints } from '_store/roads/roadsTypes';
import { roadsSelector, selectedRoadSegmentIdsSelector } from '_store/roads/selectors';
import ButtonGroup from '../../../items/ButtonGroup';
import FreeformSelect from '../../../items/FreeformSelect';
import SelectItem from '../../../items/SelectItem';
import { DenmColors, equidistantSegments, pointStyles } from '../../../routes/MapView/utils';
import { MainTrace, TraceType } from './components/MainTrace';
import { DenmOptionals } from './components/Optionals';
import { Trace } from './components/Trace';
import { TracesList } from './components/TracesList';
import styles from './styles';
import { settingsSelector } from '_store/settings/selectors';
import { AlertIcon } from '../../../icons';
import { updateLocalStorage, getFromLocalStorage } from '../../../../../utils';

// TODO Replace this with a proper validation library (when we have more time)
export function isFormValid(form: CreateDENMForm): boolean {
  return (
    form.cause !== null &&
    form.subcause !== undefined &&
    form.referencePoint !== null &&
    form.tracezoneCoordinates &&
    form.frequencybroadcasting !== null &&
    !Number.isNaN(form.frequencybroadcasting) &&
    form.validityperiod !== null &&
    !Number.isNaN(form.validityperiod) &&
    form.validitydurationpercentage !== null &&
    !Number.isNaN(form.validitydurationpercentage) &&
    form.validitydurationpercentage >= 10 &&
    form.validitydurationpercentage <= 90
  );
}

interface DenmCreateProps {
  closeDialog: () => void;
}

const DenmCreate = ({ closeDialog }: DenmCreateProps) => {
  const { l10n } = useLocalization();
  const classes = styles();
  const dispatch = useAppDispatch();

  const formState = useSelector((state: RootState) => state.forms['manage-denm']);
  const pointSelection = useSelector((state: RootState) => state.application.pointSelections);
  const { active: psActive, points } = pointSelection;
  const currentSelection = useSelector((state: RootState) => state.application.currentSelection);
  const selectedRoadSegmentIds: string[] = useSelector(selectedRoadSegmentIdsSelector);
  const settings = useSelector(settingsSelector);

  const roads = useSelector(roadsSelector);
  const denmCauses = useSelector(causesSelector);
  const denmEvents = useSelector(subCausesByCauseSelector);
  const roadWorksId = useSelector(denmCauseRoadWorksSelector);

  const { busy } = useSelector((state: RootState) => state.application.activeDialog);
  const referencePointSelected =
    formState.referencePoint !== null && formState.referencePoint !== undefined;

  const extraMapElements = useSelector(extraSelector);
  const extraTraceMapElements = useSelector(extraTraceMapElementsSelector);

  const [mainTrace, setMainTrace] = useState<Coordinate[]>([]);
  const [selectedTraceIndex, setSelectedTraceIndex] = useState<number>(null);
  const [selectedTrace, setSelectedTrace] = useState<string>(null);

  const [isAwarenessDistance, setIsAwarenessDistance] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [newFormState, setNewFormState] = useState<CreateDENMPayload>(null);

  const [localValidityDurationPercentage, setLocalValidityDurationPercentage] = useState(
    formState.validitydurationpercentage?.toString() ?? '',
  );

  useEffect(() => {
    dispatch(denmsReducer.actions.causesRequest());

    const savedFormState = getFromLocalStorage('tempDenmFormState');
    const savedMainTrace = getFromLocalStorage('tempDenmMainTrace');

    if (savedFormState) {
      if (savedFormState.cause) {
        dispatch(denmFormReducer.actions.causeChanged(savedFormState.cause));
        dispatch(denmsReducer.actions.subCausesByCauseRequest(savedFormState.cause));

        setIsAwarenessDistance(
          adverseWeatherConditions.includes(
            denmCauses.find((cause) => cause.id === savedFormState.cause)?.description,
          ),
        );
      }

      if (savedFormState.subcause) {
        dispatch(denmFormReducer.actions.subcauseChanged(savedFormState.subcause));
      }

      if (savedFormState.validityperiod) {
        dispatch(denmFormReducer.actions.validityPeriodChanged(savedFormState.validityperiod));
      }

      if (savedFormState.periodUnit) {
        dispatch(denmFormReducer.actions.periodUnitChanged(savedFormState.periodUnit));
      }

      if (savedFormState.frequencybroadcasting) {
        dispatch(
          denmFormReducer.actions.frequencyBroadcastingChanged(
            savedFormState.frequencybroadcasting,
          ),
        );
      }

      if (savedFormState.referencePoint) {
        dispatch(denmFormReducer.actions.referencePointChanged(savedFormState.referencePoint));

        const referencePointElements = [
          {
            ...pointStyles[PointSelectionType.DENM_REFERENCE_POINT],
            loc: savedFormState.referencePoint,
          },
        ];

        dispatch(
          appReducer.actions.setMapElement({
            id: PointSelectionType.DENM_REFERENCE_POINT,
            elements: referencePointElements,
          }),
        );
      }

      if (savedFormState.roadSegmentId) {
        dispatch(denmFormReducer.actions.roadSegmentIdChanged(savedFormState.roadSegmentId));
      }

      if (savedFormState.optional) {
        dispatch(denmFormReducer.actions.optionalChanged(savedFormState.optional));
      }

      if (savedFormState.validityduration) {
        dispatch(denmFormReducer.actions.validityDurationChanged(savedFormState.validityduration));
      }

      if (savedFormState.validitydurationpercentage) {
        dispatch(
          denmFormReducer.actions.validityDurationPercentageChanged(
            savedFormState.validitydurationpercentage,
          ),
        );
        setLocalValidityDurationPercentage(savedFormState.validitydurationpercentage.toString());
      }

      if (savedFormState.historyzoneCoordinates) {
        dispatch(denmFormReducer.actions.historyzoneChanged(savedFormState.historyzoneCoordinates));

        const historyPoints = savedFormState.historyzoneCoordinates;
        const historyElements = historyPoints.map((loc) => ({
          ...pointStyles[PointSelectionType.DENM_HISTORY],
          loc,
        }));

        if (historyPoints.length > 1) {
          historyElements.push({
            type: MapElementType.POLYLINE,
            loc: historyPoints[0],
            points: historyPoints.map((p, _i, arr) => [p[0] - arr[0][0], p[1] - arr[0][1]]),
            options: {
              color: pointStyles[PointSelectionType.DENM_HISTORY].options.color,
            },
          });
        }

        dispatch(
          appReducer.actions.setMapElement({
            id: PointSelectionType.DENM_HISTORY,
            elements: historyElements,
          }),
        );
      }

      if (savedFormState.tracezoneCoordinates && savedFormState.tracezoneCoordinates.length > 0) {
        dispatch(denmFormReducer.actions.tracezoneChanged(savedFormState.tracezoneCoordinates));

        savedFormState.tracezoneCoordinates.forEach((tracePoints, index) => {
          const traceElements = tracePoints.map((loc) => ({
            ...pointStyles[PointSelectionType.DENM_TRACES],
            loc,
          }));

          if (tracePoints.length > 1) {
            traceElements.push({
              type: MapElementType.POLYLINE,
              loc: tracePoints[0],
              points: tracePoints.map((p, _i, arr) => [p[0] - arr[0][0], p[1] - arr[0][1]]),
              options: {
                color: pointStyles[PointSelectionType.DENM_TRACES].options.color,
              },
            });
          }

          const paramsId = PointSelectionType.DENM_TRACES + index;

          dispatch(
            appReducer.actions.setMapElement({
              id: paramsId,
              elements: traceElements,
            }),
          );

          dispatch(
            appReducer.actions.setTraceMapElement({
              id: paramsId,
              elements: tracePoints,
            }),
          );
        });
      }
    }

    if (savedMainTrace && savedMainTrace.length > 0) {
      setMainTrace(savedMainTrace);

      const elements = savedMainTrace.map((loc) => ({
        ...pointStyles[PointSelectionType.DENM_TRACES],
        options: {
          ...pointStyles[PointSelectionType.DENM_TRACES].options,
          color: DenmColors.MAIN_TRACE,
        },
        loc,
      }));

      if (savedMainTrace.length > 1) {
        elements.push({
          type: MapElementType.POLYLINE,
          loc: savedMainTrace[0],
          points: savedMainTrace.map((p, _i, arr) => [p[0] - arr[0][0], p[1] - arr[0][1]]),
          options: {
            weight: 4,
            color: DenmColors.MAIN_TRACE,
          },
        });
      }

      const paramsId = PointSelectionType.DENM_TRACES + 'main';

      dispatch(
        appReducer.actions.setMapElement({
          id: paramsId,
          elements,
        }),
      );

      dispatch(
        appReducer.actions.setTraceMapElement({
          id: paramsId,
          elements: savedMainTrace,
        }),
      );
    }

    return () => {
      // Cleanup custom elements
      dispatch(appReducer.actions.removeMapElements(PointSelectionType.DENM_REFERENCE_POINT));
      dispatch(appReducer.actions.removeMapElements(PointSelectionType.DENM_HISTORY));
      dispatch(appReducer.actions.removeMapElements(PointSelectionType.DENM_TRACES));
      dispatch(appReducer.actions.removeTraceMapElements());
    };
  }, []);

  const isActiveSelection = useMemo(
    () => ({
      [PointSelectionType.DENM_REFERENCE_POINT]:
        psActive && currentSelection === PointSelectionType.DENM_REFERENCE_POINT,
      [PointSelectionType.DENM_HISTORY]:
        psActive && currentSelection === PointSelectionType.DENM_HISTORY,
      [PointSelectionType.DENM_TRACES]:
        psActive && currentSelection === PointSelectionType.DENM_TRACES,
    }),
    [psActive, currentSelection],
  );

  const isSelectionButtonDisabled = useMemo(
    () => ({
      [PointSelectionType.DENM_REFERENCE_POINT]:
        (psActive && !isActiveSelection[PointSelectionType.DENM_REFERENCE_POINT]) || busy,
      [PointSelectionType.DENM_HISTORY]:
        !referencePointSelected ||
        (psActive && !isActiveSelection[PointSelectionType.DENM_HISTORY]) ||
        busy,
      [PointSelectionType.DENM_TRACES]:
        !referencePointSelected ||
        (psActive && !isActiveSelection[PointSelectionType.DENM_TRACES]) ||
        busy,
    }),
    [
      referencePointSelected,
      psActive,
      isActiveSelection,
      busy,
      formState.tracezoneCoordinates,
      mainTrace,
    ],
  );

  const isTracesZoneDisabled = useMemo(
    () =>
      isSelectionButtonDisabled[PointSelectionType.DENM_TRACES] ||
      (formState.tracezoneCoordinates && formState.tracezoneCoordinates.length >= 6),
    [isSelectionButtonDisabled, formState],
  );

  const startSelection = useCallback(
    (params: PointSelectionParams) => {
      if (params.type !== PointSelectionType.DENM_TRACES) {
        dispatch(appReducer.actions.removeMapElements(params.type));
      }
      dispatch(appReducer.actions.pointSelectionStarted(params));
    },
    [dispatch],
  );

  type CallbackType = (key: string) => void;

  const processArray = (
    index: number,
    formState: any,
    extraTraceMapElements: any,
    extraMapElements: any,
    callback: CallbackType,
  ) => {
    const targetArray = formState.tracezoneCoordinates[index];
    const matchingKey = Object.keys(extraTraceMapElements).find((key) =>
      isEqual(extraTraceMapElements[key], targetArray),
    );

    const correspondingIndex = matchingKey ? extraMapElements[matchingKey] : undefined;
    if (correspondingIndex !== undefined) {
      callback(matchingKey);
    }
  };

  const deleteTrace = useCallback(
    (index: number) => {
      processArray(index, formState, extraTraceMapElements, extraMapElements, (matchingKey) => {
        dispatch(appReducer.actions.removeMapElements(matchingKey));
      });

      dispatch(denmFormReducer.actions.tracezoneRemoved(index));
      if (selectedTraceIndex === index) {
        setSelectedTrace(null);
        setSelectedTraceIndex(null);
      }
    },
    [dispatch, formState.tracezoneCoordinates, extraTraceMapElements, extraMapElements],
  );

  const deleteMainTrace = useCallback(() => {
    dispatch(appReducer.actions.removeMapElements(PointSelectionType.DENM_TRACES + 'main'));
    setMainTrace([]);
    setSelectedTraceIndex(null);
  }, [dispatch, formState.tracezoneCoordinates, extraTraceMapElements, extraMapElements]);

  const createTracePoints = (type: TraceType, checkSameLine: boolean = true) => {
    let tracePoints = [];
    if (type === TraceType.AUTOMATIC) {
      const roadSegment = formState.roadSegmentId ?? selectedRoadSegmentIds[0];
      const firstSelectedRoadSegment: Waypoints = (roads.find((r) => r.id === roadSegment)
        ?.waypoints || []) as Waypoints;
      if (Array.isArray(firstSelectedRoadSegment) && firstSelectedRoadSegment.length === 0) {
        return [];
      }
      try {
        const es = equidistantSegments(
          firstSelectedRoadSegment,
          points[0],
          points[1],
          checkSameLine,
        );
        const coordinates: Coordinate[] = es.geometry.coordinates.map((position) => [
          position[0],
          position[1],
        ]);
        dispatch(appReducer.actions.changePointCount(PointCount.MANY));
        coordinates.map((loc) => dispatch(appReducer.actions.pointSelected(loc)));
        tracePoints = coordinates;
      } catch (error) {
        dispatch(
          appReducer.actions.toastAdded({
            id: l10n.getString('forms-denm-automatic-trace-error'),
          }),
        );
        return [];
      }
    } else {
      tracePoints = points;
    }
    return tracePoints;
  };

  const endSelection = useCallback(
    (params: PointSelectionParams, traceType?: TraceType) => {
      // Take existing points
      const tracePoints = traceType ? createTracePoints(traceType, false) : points;

      switch (params.type) {
        case PointSelectionType.DENM_REFERENCE_POINT:
          dispatch(appReducer.actions.removeAllMapElements());
          dispatch(denmFormReducer.actions.referencePointChanged(tracePoints[0]));
          dispatch(denmFormReducer.actions.tracezoneRemoveAll());
          setMainTrace([]);
          break;
        case PointSelectionType.DENM_HISTORY:
          dispatch(denmFormReducer.actions.historyzoneChanged(tracePoints));
          break;
        case PointSelectionType.DENM_TRACES:
          if (tracePoints.length > 0) {
            dispatch(denmFormReducer.actions.tracezoneChanged(tracePoints));
          }
          break;
        default:
          break;
      }

      // Show them on map
      const elements = tracePoints.map((loc) => ({
        ...pointStyles[params.type],
        loc,
      }));

      // If multiple points have been selected, draw a line between them
      if (tracePoints.length > 1) {
        elements.push({
          type: MapElementType.POLYLINE,
          loc: tracePoints[0],
          points: tracePoints.map((p, _i, arr) => [p[0] - arr[0][0], p[1] - arr[0][1]]),
          options: {
            color: pointStyles[params.type].options.color,
          },
        });
      }

      let paramsId =
        params.type === PointSelectionType.DENM_TRACES
          ? PointSelectionType.DENM_TRACES + Object.keys(extraTraceMapElements).length
          : params.type;

      dispatch(
        appReducer.actions.setMapElement({
          id: paramsId,
          elements,
        }),
      );

      if (params.type === PointSelectionType.DENM_TRACES && tracePoints.length > 0) {
        dispatch(appReducer.actions.setTraceMapElement({ id: paramsId, elements: tracePoints }));
      }

      // Stop recording coordinates
      dispatch(appReducer.actions.pointSelectionAborted());
    },
    [dispatch, points, pointStyles],
  );

  const endSelectionMainTrace = useCallback(
    (type: TraceType) => {
      const mainTracePoints = createTracePoints(type);

      // Show them on map
      const elements = mainTracePoints.map((loc) => ({
        ...pointStyles[PointSelectionType.DENM_TRACES],
        options: {
          ...pointStyles[PointSelectionType.DENM_TRACES].options,
          color: DenmColors.MAIN_TRACE,
        },
        loc,
      }));

      // If multiple points have been selected, draw a line between them
      if (mainTracePoints.length > 1) {
        elements.push({
          type: MapElementType.POLYLINE,
          loc: mainTracePoints[0],
          points: mainTracePoints.map((p, _i, arr) => [p[0] - arr[0][0], p[1] - arr[0][1]]),
          options: {
            weight: 4,
            color: DenmColors.MAIN_TRACE,
          },
        });
      }

      let paramsId = PointSelectionType.DENM_TRACES + 'main';

      setMainTrace(mainTracePoints);
      dispatch(
        appReducer.actions.setMapElement({
          id: paramsId,
          elements,
        }),
      );
      dispatch(appReducer.actions.setTraceMapElement({ id: paramsId, elements: mainTracePoints }));
      dispatch(appReducer.actions.pointSelectionAborted());
    },
    [dispatch, points, pointStyles, formState.roadSegmentId, selectedRoadSegmentIds],
  );

  const toggleSelection = useCallback(
    (type: PointSelectionType, traceType?: TraceType) => {
      dispatch(appReducer.actions.setTraceType(traceType));
      const pointCountMap = {
        [PointSelectionType.DENM_REFERENCE_POINT]: PointCount.ONE,
        [PointSelectionType.DENM_HISTORY]: PointCount.TWENTYTHREE,
      };
      const pointCount =
        pointCountMap[type] ||
        (traceType === TraceType.AUTOMATIC ? PointCount.TWO : PointCount.FORTY);

      const params: PointSelectionParams = {
        type,
        pointCount,
        style: pointStyles[type],
      };

      if (isActiveSelection[type]) {
        endSelection(params, traceType);
      } else {
        startSelection(params);
      }
    },
    [pointStyles, isActiveSelection, endSelection, startSelection],
  );

  const submitForm = () => {
    const updatedTracezoneCoordinates = [mainTrace, ...formState.tracezoneCoordinates];
    const traceZoneCoordinates = {
      type: 'MultiLineString',
      coordinates: updatedTracezoneCoordinates,
    };
    const hzc = formState.historyzoneCoordinates;
    const historyZoneCoordinates =
      hzc && hzc.length > 1
        ? {
            type: 'LineString',
            coordinates: hzc,
          }
        : null;
    const referencePoint = {
      type: 'Point',
      coordinates: formState.referencePoint,
    };
    const validtyPeriodInMs = convertToMilliseconds({
      [formState.periodUnit]: formState.validityperiod,
    });

    const newFormState: CreateDENMPayload = {
      traceZoneCoordinates,
      ...(historyZoneCoordinates && { historyZoneCoordinates }),
      referencePoint,
      validityPeriod: validtyPeriodInMs,
      broadcastingFrequency: formState.frequencybroadcasting,
      subCauseId: formState.subcause,
      roadSegmentId:
        formState.roadSegmentId ??
        (selectedRoadSegmentIds && selectedRoadSegmentIds.length === 1
          ? selectedRoadSegmentIds[0]
          : null),
      optional: formState.optional,
      validityDuration: formState.validityduration ?? 720,
      validityDurationPercentage: formState.validitydurationpercentage,
    };
    setNewFormState(newFormState);

    if (!settings.distribution.denm.emeras) {
      const completeFormState = {
        ...formState,
        cause: formState.cause,
        subcause: formState.subcause,
        validityperiod: formState.validityperiod,
        periodUnit: formState.periodUnit,
        frequencybroadcasting: formState.frequencybroadcasting,
        referencePoint: formState.referencePoint,
        roadSegmentId: formState.roadSegmentId,
        optional: formState.optional,
        validityduration: formState.validityduration,
        validitydurationpercentage: formState.validitydurationpercentage,
        historyzoneCoordinates: formState.historyzoneCoordinates,
        tracezoneCoordinates: formState.tracezoneCoordinates || [],
      };

      updateLocalStorage('tempDenmFormState', completeFormState);
      updateLocalStorage('tempDenmMainTrace', mainTrace);
      setOpenModal(true);
    } else {
      createDenm({ formState: newFormState });
    }
  };

  const confirmSubmitForm = () => {
    setOpenModal(false);
    createDenm({ formState: newFormState });
  };

  const createDenm = ({ formState }: { formState: CreateDENMPayload }) => {
    updateLocalStorage('tempDenmFormState', null);
    updateLocalStorage('tempDenmMainTrace', null);

    dispatch(denmsReducer.actions.createDENM(formState));
    dispatch(denmFormReducer.actions.resetForm());
    dispatch(denmsReducer.actions.resetFilters());
    closeDialog();
  };

  const causes = denmCauses.map(({ id, description }) => ({
    value: id,
    label: description,
  }));

  const subcauses = useMemo(
    () =>
      formState.cause === null
        ? []
        : denmEvents.map(({ id, description }) => ({
            value: id,
            label: description,
          })),
    [formState, denmEvents],
  );

  const updateCause = useCallback(
    (e) => {
      const id = e.target.value;
      dispatch(denmFormReducer.actions.subcauseChanged());
      dispatch(denmsReducer.actions.subCausesByCauseRequest(id));
      dispatch(denmFormReducer.actions.causeChanged(id));

      setIsAwarenessDistance(
        adverseWeatherConditions.includes(denmCauses.find((cause) => cause.id === id)?.description),
      );
    },
    [dispatch],
  );

  const updateSubCause = useCallback(
    (e) => {
      const id = e.target.value;
      dispatch(denmFormReducer.actions.subcauseChanged(id));
    },
    [dispatch],
  );

  const toggleSelectionReference = useCallback(() => {
    toggleSelection(PointSelectionType.DENM_REFERENCE_POINT);
  }, [toggleSelection]);
  const toggleSelectionHistory = useCallback(() => {
    toggleSelection(PointSelectionType.DENM_HISTORY);
  }, [toggleSelection]);
  const toggleSelectionTraces = useCallback(
    (type: TraceType) => {
      toggleSelection(PointSelectionType.DENM_TRACES, type);
    },
    [toggleSelection],
  );

  const toggleSelectionMainTrace = useCallback(
    (type: TraceType) => {
      dispatch(appReducer.actions.setTraceType(type));
      const params: PointSelectionParams = {
        type: PointSelectionType.DENM_TRACES,
        pointCount: type === TraceType.MANUAL ? PointCount.FORTY : PointCount.TWO,
        style: {
          ...pointStyles[PointSelectionType.DENM_TRACES],
          options: {
            ...pointStyles[PointSelectionType.DENM_TRACES].options,
            color: DenmColors.MAIN_TRACE,
          },
        },
      };

      if (isActiveSelection[PointSelectionType.DENM_TRACES]) {
        endSelectionMainTrace(type);
      } else {
        startSelection(params);
      }
    },
    [toggleSelection],
  );

  const updateValidityPeriod = useCallback(
    (e) => {
      const validityPeriod = checkValidityPeriod(parseInt(e.target.value), formState.periodUnit);
      dispatch(denmFormReducer.actions.validityPeriodChanged(validityPeriod));
    },
    [dispatch, formState.periodUnit],
  );
  const updateFrequencyBroadcasting = useCallback(
    (_e, value) => {
      dispatch(denmFormReducer.actions.frequencyBroadcastingChanged(parseFloat(value)));
    },
    [dispatch],
  );
  const updatePeriodUnit = useCallback(
    (e) => {
      const validityPeriod = checkValidityPeriod(formState.validityperiod, e.target.value);
      dispatch(denmFormReducer.actions.validityPeriodChanged(validityPeriod));
      dispatch(denmFormReducer.actions.periodUnitChanged(e.target.value));
    },
    [dispatch, formState.validityperiod],
  );
  const updateAwarenessDistance = useCallback(
    (e) => {
      const awarenessDistance = e.target.value;
      dispatch(
        denmFormReducer.actions.awarenessDistanceChanged(awarenessDistance as RelevanceDistance),
      );
    },
    [dispatch],
  );
  const updateValidityDuration = useCallback(
    (e) => {
      let value = parseInt(e.target.value);

      if (value > 86400) value = 86400;
      if (value < 0) value = 0;

      dispatch(denmFormReducer.actions.validityDurationChanged(value));
    },
    [dispatch],
  );
  const debouncedValidityDurationPercentageDispatch = useCallback(
    debounce((value) => {
      let validityDurationPercentage = parseInt(value);
      if (validityDurationPercentage < 10) validityDurationPercentage = 10;
      if (validityDurationPercentage > 90) validityDurationPercentage = 90;

      setLocalValidityDurationPercentage(validityDurationPercentage.toString());

      dispatch(
        denmFormReducer.actions.validityDurationPercentageChanged(validityDurationPercentage),
      );
    }, 500),
    [dispatch],
  );

  const handleValidityDurationPercentageChange = (e) => {
    const value = e.target.value;
    setLocalValidityDurationPercentage(value);
    debouncedValidityDurationPercentageDispatch(value);
  };

  const changeStyle = useCallback(
    (matchingKey: string, color: DenmColors, weight: number, className: string) => {
      dispatch(
        appReducer.actions.changeMapElementStyle({
          id: matchingKey,
          color,
          weight,
          className,
        }),
      );
    },
    [dispatch],
  );

  const highlightTrace = useCallback(
    (index: number) => {
      processArray(index, formState, extraTraceMapElements, extraMapElements, (matchingKey) => {
        processArray(
          selectedTraceIndex,
          formState,
          extraTraceMapElements,
          extraMapElements,
          (matchingKey) => {
            changeStyle(matchingKey, DenmColors.TRACE, 3, null);
          },
        );
        if (selectedTraceIndex !== index) {
          changeStyle(matchingKey, DenmColors.TRACE_SELECTED, 3, 'stroke-polyline');
          setSelectedTrace(matchingKey);
          setSelectedTraceIndex(index);
        } else {
          setSelectedTrace(null);
          setSelectedTraceIndex(null);
        }
        changeStyle(PointSelectionType.DENM_TRACES + 'main', DenmColors.MAIN_TRACE, 3, null);
      });
      // mainTrace
      if (index === -1) {
        if (selectedTraceIndex !== -1) {
          processArray(
            selectedTraceIndex,
            formState,
            extraTraceMapElements,
            extraMapElements,
            (matchingKey) => {
              changeStyle(matchingKey, DenmColors.TRACE, 3, null);
            },
          );

          setSelectedTrace(null);
          setSelectedTraceIndex(-1);
          changeStyle(
            PointSelectionType.DENM_TRACES + 'main',
            DenmColors.TRACE_SELECTED,
            3,
            'stroke-polyline',
          );
        } else {
          changeStyle(PointSelectionType.DENM_TRACES + 'main', DenmColors.MAIN_TRACE, 3, null);
          setSelectedTrace(null);
          setSelectedTraceIndex(null);
        }
      }
    },
    [
      formState.tracezoneCoordinates,
      dispatch,
      selectedTrace,
      selectedTraceIndex,
      extraTraceMapElements,
      extraMapElements,
      changeStyle,
    ],
  );

  const navigateToSettings = useCallback(() => {
    updateLocalStorage('tempDenmFormState', {
      ...formState,
      tracezoneCoordinates: formState.tracezoneCoordinates || [],
      historyzoneCoordinates: formState.historyzoneCoordinates || [],
      referencePoint: formState.referencePoint,
      validityperiod: formState.validityperiod,
      frequencybroadcasting: formState.frequencybroadcasting,
      validityduration: formState.validityduration,
      cause: formState.cause,
      subcause: formState.subcause,
      selectedTrace: selectedTrace,
      selectedMainTrace: mainTrace,
    });

    setOpenModal(false);

    setTimeout(() => {
      dispatch(
        appReducer.actions.infoSelected({
          type: DialogType.SETTINGS,
          id: null,
        }),
      );
    }, 100);

    closeDialog();
  }, [dispatch, formState, selectedTrace, mainTrace, setOpenModal, closeDialog]);

  return (
    <CardContent className={classes.cardContent}>
      <SelectItem
        text={l10n.getString('forms-denm-cause')}
        value={formState.cause ?? ''}
        options={causes}
        disabled={busy}
        onChange={updateCause}
        tooltip={l10n.getString('forms-denm-cs-tp')}
        mandatory
      />
      <SelectItem
        text={l10n.getString('forms-denm-subcause')}
        value={formState.subcause ?? ''}
        options={subcauses}
        disabled={formState.cause === null || busy}
        onChange={updateSubCause}
        tooltip={l10n.getString('forms-denm-sb-cs-tp')}
        mandatory
      />
      <SelectItem
        text={l10n.getString('forms-rsu-road')}
        options={roads.map((road) => ({ label: road.name, value: road.id }))}
        value={
          formState.roadSegmentId ??
          (selectedRoadSegmentIds && selectedRoadSegmentIds.length == 1
            ? selectedRoadSegmentIds[0]
            : '')
        }
        onChange={(ev) => dispatch(denmFormReducer.actions.roadSegmentIdChanged(ev.target.value))}
        tooltip={l10n.getString('forms-rsu-road')}
        mandatory
      />
      <ButtonGroup
        text={l10n.getString('forms-denm-reference-point')}
        buttons={[
          {
            label: isActiveSelection[PointSelectionType.DENM_REFERENCE_POINT]
              ? l10n.getString('forms-points-end-selection')
              : l10n.getString('forms-points-start-selection'),
            disabled: isSelectionButtonDisabled[PointSelectionType.DENM_REFERENCE_POINT],
            color: 'primary',
          },
        ]}
        onClick={toggleSelectionReference}
        mandatory
      />
      {!isAwarenessDistance && (
        <ButtonGroup
          text={l10n.getString('forms-denm-history-zone')}
          buttons={[
            {
              label: isActiveSelection[PointSelectionType.DENM_HISTORY]
                ? l10n.getString('forms-points-end-selection')
                : l10n.getString('forms-points-start-selection'),
              disabled: isSelectionButtonDisabled[PointSelectionType.DENM_HISTORY],
              color: referencePointSelected && 'primary',
            },
          ]}
          onClick={toggleSelectionHistory}
        />
      )}

      {isAwarenessDistance && (
        <SelectItem
          text={l10n.getString('forms-denm-awareness-distance')}
          value={formState.optional?.management?.relevanceDistance ?? ''}
          options={Object.values(RelevanceDistance).map((value) => ({
            value,
            label: l10n.getString(`forms-denm-awareness-distance-${value}`),
          }))}
          onChange={updateAwarenessDistance}
          mandatory={isAwarenessDistance}
          tooltip={l10n.getString('forms-denm-awareness-distance')}
        />
      )}

      <MainTrace
        deleteMainTrace={deleteMainTrace}
        toggleSelectionMainTrace={toggleSelectionMainTrace}
        mainTraceLength={mainTrace.length}
        isActiveSelection={isActiveSelection[PointSelectionType.DENM_TRACES]}
        isDisabled={isSelectionButtonDisabled[PointSelectionType.DENM_TRACES]}
        selectedTraceIndex={selectedTraceIndex}
        highlightTrace={highlightTrace}
      />
      {mainTrace.length > 0 && (
        <Trace
          isActiveSelection={isActiveSelection[PointSelectionType.DENM_TRACES]}
          toggleSelectionTraces={toggleSelectionTraces}
          isTracesZoneDisabled={isTracesZoneDisabled}
        />
      )}
      {formState.tracezoneCoordinates?.map((tracePoints, index) => (
        <TracesList
          key={index}
          deleteTrace={deleteTrace}
          highlightTrace={highlightTrace}
          index={index}
          selectedTraceIndex={selectedTraceIndex}
          tracePoints={tracePoints}
        />
      ))}
      <Box style={{ display: 'grid', gridTemplateColumns: '1fr 2fr', gap: 8 }}>
        <Tooltip title={l10n.getString('forms-denm-val-pd-tp')} placement="top">
          <TextField
            id="latitude"
            label={
              <span>
                {l10n.getString('forms-denm-event-duration')}
                <span className={classes.mandatory}>*</span>
              </span>
            }
            InputLabelProps={{ style: { fontSize: 12 } }}
            type="number"
            value={formState.validityperiod?.toString() ?? ''}
            onChange={updateValidityPeriod}
            style={{ marginTop: 16, gridColumn: 1 / 2 }}
          />
        </Tooltip>
        <SelectItem
          text={l10n.getString('forms-denm-period-unit')}
          value={formState.periodUnit ?? ''}
          options={[
            { value: 'seconds', label: l10n.getString('forms-denm-period-unit-seconds') },
            { value: 'minutes', label: l10n.getString('forms-denm-period-unit-minutes') },
            { value: 'hours', label: l10n.getString('forms-denm-period-unit-hours') },
          ]}
          onChange={updatePeriodUnit}
          tooltip={l10n.getString('forms-denm-cs-tp')}
          style={{ gridColumn: 2 / 3 }}
          tooltipPlacement="top"
          mandatory
        />
      </Box>
      <FreeformSelect
        label={l10n.getString('forms-denm-broadcasting-frequency')}
        presets={['1000', '5000', '10000']}
        disabled={busy}
        value={
          isNaN(formState.frequencybroadcasting)
            ? ''
            : formState.frequencybroadcasting?.toString() ?? ''
        }
        numeric={true}
        onChange={updateFrequencyBroadcasting}
        tooltip={l10n.getString('forms-denm-br-fr-tp')}
        mandatory
      />
      <Tooltip title={l10n.getString('forms-denm-validity-duration-tp')} placement="top">
        <TextField
          id="latitude"
          label={
            <span>
              {l10n.getString('forms-denm-validity-duration')}
              <span className={classes.mandatory}>*</span>
            </span>
          }
          InputLabelProps={{ style: { fontSize: 12 } }}
          type="number"
          value={formState.validityduration?.toString() ?? '720'}
          onChange={updateValidityDuration}
          style={{ marginTop: 16, gridColumn: 1 / 2 }}
          placeholder="720"
          inputProps={{
            min: 0,
            max: 86400,
          }}
        />
      </Tooltip>
      <Tooltip title={l10n.getString('forms-denm-validity-duration-percentage-tp')} placement="top">
        <TextField
          id="latitude"
          label={
            <span>
              {l10n.getString('forms-denm-validity-duration-percentage')}
              <span className={classes.mandatory}>*</span>
            </span>
          }
          InputLabelProps={{ style: { fontSize: 12 } }}
          type="number"
          value={localValidityDurationPercentage}
          onChange={handleValidityDurationPercentageChange}
          style={{ marginTop: 16, gridColumn: 1 / 2 }}
          inputProps={{
            min: 10,
            max: 90,
          }}
        />
      </Tooltip>
      <Divider style={{ margin: '32px 0' }} />
      <DenmOptionals denmCauses={denmCauses} showAlacarte={formState.cause === roadWorksId} />
      <Button
        disabled={!isFormValid(formState) || busy || !mainTrace.length}
        variant="outlined"
        style={{ textTransform: 'none', marginTop: 32 }}
        onClick={submitForm}
      >
        {l10n.getString('forms-actions-submit')}
      </Button>
      {openModal && (
        <Dialog open={openModal} aria-labelledby="form-dialog-title">
          <Box className={classes.titleBox}>
            <AlertIcon />
            <DialogTitle id="form-dialog-title">
              {l10n.getString('forms-denm-modal-submit-warning-title')}
            </DialogTitle>
          </Box>
          <DialogContent>
            <DialogContentText>
              {l10n.getString('forms-denm-modal-submit-warning-text')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={navigateToSettings} color="secondary">
              {l10n.getString('forms-denm-modal-submit-warning-btn-settings')}
            </Button>
            <Button onClick={confirmSubmitForm} color="primary">
              {l10n.getString('forms-denm-modal-submit-warning-btn-submit')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </CardContent>
  );
};

export default React.memo(DenmCreate);
