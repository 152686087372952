import { Coordinate, DENMOptional, RelevanceDistance } from '_lib/api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Denm } from '_store/denm/denmsTypes';

export interface CreateDENMForm {
  cause: string;
  subcause: string;
  validityperiod: number;
  frequencybroadcasting: number;
  tracezoneCoordinates: Coordinate[][];
  historyzoneCoordinates: Coordinate[];
  referencePoint: Coordinate;
  periodUnit: string;
  roadSegmentId: string;
  optional?: DENMOptional;
  optionalCause?: string;
  optionalSubCause?: string;
  validityduration: number;
  validitydurationpercentage: number;
}

const initialState: CreateDENMForm = {
  cause: null,
  subcause: null,
  validityperiod: null,
  frequencybroadcasting: null,
  tracezoneCoordinates: [],
  historyzoneCoordinates: null,
  referencePoint: null,
  periodUnit: null,
  roadSegmentId: null,
  optional: {
    geo_networking: {
      distance_a: null,
    },
  },
  optionalCause: null,
  optionalSubCause: null,
  validityduration: null,
  validitydurationpercentage: null,
};

const denmFormReducer = createSlice({
  name: 'manage-denm',
  initialState,
  reducers: {
    causeChanged(state, action: PayloadAction<string>) {
      state.cause = action.payload;
    },
    subcauseChanged(state, action: PayloadAction<string>) {
      state.subcause = action.payload;
    },
    validityPeriodChanged(state, action: PayloadAction<number>) {
      state.validityperiod = action.payload;
    },
    frequencyBroadcastingChanged(state, action: PayloadAction<number>) {
      state.frequencybroadcasting = action.payload;
    },
    tracezoneChanged(state, action: PayloadAction<Coordinate[]>) {
      const coordinates = action.payload;
      state.tracezoneCoordinates = [...state.tracezoneCoordinates, coordinates];
    },
    tracezoneRemoved(state, action: PayloadAction<number>) {
      state.tracezoneCoordinates.splice(action.payload, 1);
    },
    tracezoneRemoveAll(state) {
      state.tracezoneCoordinates = [];
    },
    historyzoneChanged(state, action: PayloadAction<Coordinate[]>) {
      state.historyzoneCoordinates = action.payload;
    },
    referencePointChanged(state, action: PayloadAction<Coordinate>) {
      state.referencePoint = action.payload;
    },
    periodUnitChanged(state, action: PayloadAction<string>) {
      state.periodUnit = action.payload;
    },
    roadSegmentIdChanged(state, action: PayloadAction<string>) {
      state.roadSegmentId = action.payload;
    },
    optionalChanged(state, action: PayloadAction<DENMOptional>) {
      state.optional = action.payload;
    },
    optionalCauseChanged(state, action: PayloadAction<string>) {
      state.optionalCause = action.payload;
    },
    optionalSubCauseChanged(state, action: PayloadAction<string>) {
      state.optionalSubCause = action.payload;
    },
    awarenessDistanceChanged(state, action: PayloadAction<RelevanceDistance>) {
      if (!state.optional.management) {
        state.optional.management = {
          altitude: { altitudeValue: null, altitudeConfidence: null },
          relevanceDistance: action.payload,
          relevanceTrafficDirection: null,
          stationType: null,
        };
      } else {
        state.optional.management.relevanceDistance = action.payload;
      }
    },
    validityDurationChanged(state, action: PayloadAction<number>) {
      state.validityduration = action.payload;
    },
    validityDurationPercentageChanged(state, action: PayloadAction<number>) {
      state.validitydurationpercentage = action.payload;
    },
    prefillForm(_state, action: PayloadAction<Denm>) {
      const denm = action.payload;
      return {
        ...initialState,
        subcause: denm.subCauseId,
        validityperiod: denm.validityPeriod,
        frequencybroadcasting: denm.broadcastingFrequency,
        tracezoneCoordinates: denm.traceZoneCoordinates.coordinates.slice(1),
        historyzoneCoordinates: denm.historyZoneCoordinates?.coordinates,
        referencePoint: denm.referencePoint.coordinates,
        roadSegmentId: denm.roadSegmentId,
        optional: denm.optional,
        validityduration: denm.validityDuration,
        validitydurationpercentage: denm.validityDurationPercentage,
      };
    },
    resetForm() {
      return initialState;
    },
    resetGeoNetworking() {
      return {
        ...initialState,
        optional: {
          geo_networking: { distance_a: null },
        },
      };
    },
  },
});

export default denmFormReducer;
