import { useLocalization } from '@fluent/react';
import { CardContent, Grid, Typography } from '@material-ui/core';
import { useAppDispatch } from '_store';
import { DialogType } from '_store/application/types';
import denmsReducer from '_store/denm/denmsReducers';
import { causesSelector, loadingSelector, subCausesSelector } from '_store/denm/selectors';
import { defaultIcon, useDenmsForRoadSegment } from '_store/denm/utils';
import { selectedRoadSegmentIdsSelector } from '_store/roads/selectors';
import { isFuture } from 'date-fns';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DenmSourceFilter } from '_store/denm/denmsTypes';
import { hasDefinedPropertyDeep } from '_store/utils';
import { iconForCause } from '../../icons/utils/denm';
import { EtsiMetaData } from '../Denm/DenmList/Card/Etsi';
import { DenmOptionalDetail } from '../Denm/DenmList/Card/Optional';
import { SincroMetaData } from '../Denm/DenmList/Card/Sincro';
import EventLayout from './EventLayout';
import styles from './styles';
import { RsusMetaData } from '../Denm/DenmList/Card/Rsus';

interface DenmLayoutProps {
  id: string;
  onUpdateDenm?: (idExpanded: string) => void;
  onCloneDenm?: (idExpanded: string) => void;
}

const DenmLayout: FC<DenmLayoutProps> = ({ id, onUpdateDenm, onCloneDenm }) => {
  const classes = styles();
  const { l10n } = useLocalization();
  const dispatch = useAppDispatch();

  const causes = useSelector(causesSelector);
  const subCauses = useSelector(subCausesSelector);
  const selectedRoadSegmentIds: string[] = useSelector(selectedRoadSegmentIdsSelector);
  const events = useDenmsForRoadSegment(selectedRoadSegmentIds);
  const isDenmLoading = useSelector(loadingSelector);

  const onDeleteDenm = () => {
    dispatch(denmsReducer.actions.deleteDENM(id));
  };

  const onDisableDenm = () => {
    dispatch(denmsReducer.actions.disableDENM({ ids: [id], disable: true }));
  };

  const onEnableDenm = () => {
    dispatch(denmsReducer.actions.disableDENM({ ids: [id], disable: false }));
  };

  const event = events.find((denm) => denm.id === id || denm.datexiiId === id);

  if (isDenmLoading) return <FontAwesomeIcon icon={faSpinner} spin style={{ marginLeft: 10 }} />;

  if (!event) {
    return (
      <EventLayout
        type={DialogType.DENM}
        active={false}
        disabled={false}
        header={l10n.getString('detail-denm-missing-header')}
      >
        {l10n.getString('detail-denm-missing-description-0')}
        <br />
        <strong>{id}</strong>
        <br />
        {l10n.getString('detail-denm-missing-description-1')}
      </EventLayout>
    );
  }

  const subCause = subCauses.find((sc) => sc.id === event?.subCauseId);
  const cause = subCause && causes.find((c) => c.id === subCause.causeId);

  return (
    <EventLayout
      type={DialogType.DENM}
      active={isFuture(new Date(event.expiresAt))}
      disabled={event.disabled && new Date(event.expiresAt) > new Date()}
      header={cause?.description ?? l10n.getString('detail-denm-default-event')}
      date={new Date(event.createdAt)}
      onDelete={event.source === DenmSourceFilter.EMERAS && onDeleteDenm}
      onDisable={event.source === DenmSourceFilter.EMERAS && onDisableDenm}
      onEnable={event.source === DenmSourceFilter.EMERAS && onEnableDenm}
      causeIcon={iconForCause(cause, subCause) ?? defaultIcon}
      onUpdate={
        event.source === DenmSourceFilter.EMERAS && onUpdateDenm
          ? () => onUpdateDenm(event.id)
          : undefined
      }
      onClone={
        event.source === DenmSourceFilter.EMERAS && onCloneDenm
          ? () => onCloneDenm(event.id)
          : undefined
      }
    >
      <CardContent className={classes.detailStyle}>
        <Grid container spacing={1} style={{ marginTop: 10 }}>
          <Grid item xs={12}>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-denm-id')} {`\n`}
              <strong>{event.id}</strong>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-denm-cause')} {`\n`}
              <strong>
                {cause ? cause.description : l10n.getString('detail-denm-missing-value-unknown')}
              </strong>
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-denm-subcause')} {`\n`}{' '}
              <strong>
                {subCause
                  ? subCause.description
                  : l10n.getString('detail-denm-missing-value-unknown')}
              </strong>
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.gridVertical}>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-denm-init-date')} {`\n`}{' '}
              <strong>{new Date(event.createdAt).toLocaleString()}</strong>
            </Typography>

            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-denm-final-date')} {`\n`}
              <strong>{new Date(event.expiresAt).toLocaleString()}</strong>
            </Typography>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-denm-reference-point')} {`\n`}(
              <strong>
                {event.referencePoint.coordinates[1]}, {event.referencePoint.coordinates[0]})
              </strong>
            </Typography>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-denm-source')} {`\n`}
              <strong>
                {event.source === DenmSourceFilter.CONNEX
                  ? l10n.getString('forms-event-source-vehicles')
                  : event.source}
              </strong>
            </Typography>
            {event.source === DenmSourceFilter.DATEXII && (
              <Typography
                component={'span'}
                variant={'body2'}
                paragraph
                className={classes.textStyle}
              >
                {l10n.getString('forms-denm-datexii-id')} {`\n`}
                <strong>{event.datexiiId}</strong>
              </Typography>
            )}
            <DenmOptionalDetail
              denm={event}
              showManagement={
                event.optional?.management && hasDefinedPropertyDeep(event.optional?.management)
              }
              showSituation={
                event.optional?.situation && hasDefinedPropertyDeep(event.optional?.situation)
              }
              showLocation={
                event.optional?.location && hasDefinedPropertyDeep(event.optional?.location)
              }
              showAlacarte={
                event.optional?.alacarte && hasDefinedPropertyDeep(event.optional?.alacarte)
              }
            />
            {event.metadata?.etsi && <EtsiMetaData etsiData={event.metadata?.etsi} />}
          </Grid>
        </Grid>
        {event.metadata?.sincro && (
          <>
            {l10n.getString('details-metadata')} <br />
            <SincroMetaData sincroData={event.metadata?.sincro} />
          </>
        )}
        {event.metadata?.rsus && (
          <>
            {l10n.getString('selected-devices')} <br />
            <RsusMetaData rsus={event.metadata?.rsus} />
          </>
        )}
      </CardContent>
    </EventLayout>
  );
};

export default React.memo(DenmLayout);
