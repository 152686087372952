import DateFnsUtils from '@date-io/date-fns';
import { Tooltip } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { FC } from 'react';

export interface DateItemProps {
  label: string;
  value: Date;
  format?: string;
  disabled?: boolean;
  onChange?: (newdate: Date) => void;
  tooltip?: string;
  disablePast?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

const DateItem: FC<DateItemProps> = (props) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <KeyboardDateTimePicker
      // disableToolbar
      variant="inline"
      label={props.label}
      format={props.format ?? 'dd/MM/yy-HH:mm'}
      value={props.value}
      onChange={props.onChange}
      disablePast={props.disablePast ?? true}
      minDate={props.minDate}
      maxDate={props.maxDate}
      disabled={props.disabled}
      inputProps={{
        style: {
          fontSize: '0.7vw',
          paddingTop: 5,
          marginRight: -20,
          marginLeft: -1,
          marginTop: 5,
        },
      }}
      InputLabelProps={{
        shrink: true,
      }}
      keyboardIcon={
        <Tooltip title={props.tooltip} placement="right">
          <CalendarIcon fontSize="small" style={{ margin: -35 }} />
        </Tooltip>
      }
    />
  </MuiPickersUtilsProvider>
);

export default React.memo(DateItem);
